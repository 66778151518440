import React from 'react';
import './plancard.scss';

const PlanCard = (props) => {
  const { CardValue } = props;
  const {
    amount, activation, valid, indication, activeClass, completePlan,
  } = CardValue;

  return (
    <div className={`plan-card ${activeClass ? 'active' : ''}`}>
      <span className="edit sprite-img-before" />
      <h1>
        $
        {amount}
      </h1>
      {!completePlan ? (
        <div>
          <h2>{activation}</h2>
          <p className="valid">
            {valid}
          </p>
          <p className="watermark-value">{indication}</p>
        </div>
      ) : (
        <p>Payment Plan Complete</p>
      )}
    </div>
  );
};

export default PlanCard;
