import React from 'react';
import './other.scss';

const Other = () => (
  <div className="Notification-acc-wrap">
    Other
  </div>
);

export default Other;
