import React from 'react';
import Button from '../Button';
import './toast.scss';

const Toast = (props) => {
  const { toastList: toast, position, handleCallback } = props;
  return (
    <div className={`notification-container ${position}`}>
      <div
        className={`notification-toast toast ${position}`}
        style={{ backgroundColor: toast.backgroundColor }}
      >
        <Button onClick={handleCallback}>
          X
        </Button>
        <div className="status-icon" />
        <p className="notification-message">
          {toast.description}
        </p>
      </div>
    </div>
  );
};

export default Toast;

Toast.defaultProps = {
  position: 'bottom-right',
};
