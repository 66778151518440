import React, { useState } from 'react';
import AppointmentBookList from '../../../components/AppointmentBookList';
import BookAppointmentLocation from '../../../components/BookAppointmentLocation';
import Toast from '../../../components/Toast';
import './bookappointment.scss';

const AppointmentBookData = {
  image: '/images/user-img-1.png',
  name: 'Dr. Carter Herwitz',
  occupasion: 'Ophthalmologist/ Eye Surgeon',
  location: '554 South Pasadena',
  time: '4 mi',
};
const AppointmentBookLocation = {
  image: '/images/user-img-1.png',
  name: 'Dr. Carter Herwitz',
  occupation: 'Ophthalmologist/ Eye Surgeon',
  location1: '554 South Pasadena',
  location2: 'Tustin',
  location3: 'Brooklyn',
  location4: 'Boston',

};

const testList = {
  id: 1,
  title: 'Success',
  description: 'Your Booking Slot is Confirm',
  backgroundColor: '#EFF1F5',
  icon: '',
};

const BookAppointment = () => {
  const [showDoctor, setShowDoctor] = useState(true);
  const [toastData, setToastData] = useState({});
  // const [showDoctorList, setShowDoctorList] = useState(false);
  const showDoctorAction = () => {
    setShowDoctor(!showDoctor);
  };
  const setSelectDoctor = () => {
    setShowDoctor(!showDoctor);
  };
  const submitToastData = () => {
    setToastData(testList);
  };
  return (
    <div className="book-appointment-section">
      { toastData.id
      && (
      <Toast
        toastList={toastData}
        position="top-right"
        handleCallback={() => setToastData({})}
      />
      )}
      <h2>Book Appointment</h2>
      <p className="sub-title">
        Select the
        {' '}
        <span>{showDoctor ? 'doctor' : 'Location'}</span>
        {' '}
        for your appointment:
      </p>
      {showDoctor ? (
        <div className="appointment-book-list-wrapper">
          <AppointmentBookList
            handleOnClick={setSelectDoctor}
            AppointmentBookData={AppointmentBookData}
          />
          <AppointmentBookList AppointmentBookData={AppointmentBookData} />
          <AppointmentBookList AppointmentBookData={AppointmentBookData} />
          <AppointmentBookList AppointmentBookData={AppointmentBookData} />
          <AppointmentBookList AppointmentBookData={AppointmentBookData} />
          <AppointmentBookList AppointmentBookData={AppointmentBookData} />
        </div>
      )
        : (
          <BookAppointmentLocation
            AppointmentBookLocation={AppointmentBookLocation}
            submitToastData={submitToastData}
            showDoctorAction={showDoctorAction}
          />
        )}
    </div>
  );
};

export default BookAppointment;
