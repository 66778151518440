import React from 'react';
import MainContent from '../../screens/MainContent';
import './content.scss';

function Content() {
  return (
    <div className="content-wrap">
      <div className="welcome-section">
        <MainContent />
      </div>
    </div>
  );
}

export default Content;
