import { Map, OrderedMap } from 'immutable';

import {
  GET_PATHS,
  NEW_PATH,
  EDIT_PATH,
  DELETE_PATH,
  UPDATE_CURRENT_TAB_NAME,
} from '../actions/navigation';

const initialState = Map({ tabsCount: 0 });

const listNameMap = {
  claims: { title: 'Claims List' },
  patients: { title: 'Patients List' },
  billing: { title: 'Billing List' },
  doctor: { title: 'Summary' },
  technician: { title: 'Summary' },
  technicianLilyLee: { title: 'Summary' },
  contract: { title: 'Contract List' },
  document: { title: 'Document List' },
  provider: { title: 'Provider List' },
  businessEntity: { title: 'Business Entity' },
  users: { title: 'Users List' },
  locations: { title: 'Locations List' },
  payer: { title: 'Payer List' },
  masters: { title: 'Masters List' },
  cpt: { title: 'CPT List' },
  ba: { title: 'Schedule Appointments' },
};

const actionsMap = {
  [GET_PATHS]: (state, action) => state.get(action.id),
  [NEW_PATH]: (state, action) => {
    let tabsPath = OrderedMap(state.get(action.data.tabName)) || OrderedMap({});
    let tabCount = state.get('tabsCount') + 1;
    const tabName = listNameMap[action.data.tabName]
      ? listNameMap[action.data.tabName]?.title
      : action.data.tabName;
    if (tabsPath.size === 0) {
      tabsPath = tabsPath.set('parent', {
        parent: true,
        params: action.data?.parentInfo?.params
          ? { ...action.data?.parentInfo?.params, data: { name: tabName } }
          : { data: { name: tabName } },
        path: action.data?.parentInfo?.path,
        name: listNameMap[action.data.tabName]?.title
          ? listNameMap[action.data.tabName]?.title
          : action.data.tabName,
        id: action.data?.parentInfo?.id,
        tabId: 'parent',
        tabModuleName: action.data.tabName,
        tabName: action.data.tabName,
      });
      tabCount += 1;
    }
    const paths = tabsPath.get(action.id) || OrderedMap({});
    const moduleData = tabsPath?.toJS() || {};
    const { tabId } = action.data;
    const newPaths = paths.set(tabId, { tabId, ...action.data }).toJS();
    return state.set(action.data.tabName, { ...moduleData, ...newPaths }).set('tabsCount', tabCount);
  },
  [EDIT_PATH]: (state, action) => {
    const moduleData = state.toJS()[action.data.tabName] || {};
    const paths = OrderedMap(moduleData);
    const { tabId } = action.data;
    const newPaths = paths.set(tabId, { tabId, ...action.data }).toJS();
    return state.set(action.data.tabModuleName, { ...moduleData, ...newPaths });
  },
  [DELETE_PATH]: (state, action) => {
    const paths = OrderedMap(state.get(action.data.tabModuleName)) || OrderedMap({});
    const newPaths = paths.delete(action.data.tabId);
    return state.set(action.data.tabModuleName, newPaths);
  },
  [UPDATE_CURRENT_TAB_NAME]: (state, { name, tabModuleName }) => {
    const tabId = state.get('currentTab');
    const allTabs = OrderedMap(state.get(tabModuleName));
    const tab = allTabs.get(`${tabId}`);
    const { params } = tab;
    const { data } = params;
    return state.set(tabModuleName, allTabs.set(`${tabId}`, {
      ...tab,
      params: {
        ...params,
        data: {
          ...data,
          name,
        },
      },
    }));
  },
  SET_CURRENT_TAB: (state, { currentTab }) => state.set('currentTab', currentTab),
};

export default function navigation(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
