import React, { useState } from 'react';
import { Upload } from 'antd';
import Button from '../Button';
// import { UploadOutlined } from '@ant-design/icons';
import './fileUploader.scss';

const FileUploader = (props) => {
  const [showDefaultText, setShowDefaultText] = useState(true);
  const { defaultFileList } = props;
  const setLabelValue = () => {
    setShowDefaultText(!showDefaultText);
  };
  return (
    <Upload
      className="upload-wrap"
      onChange={() => {
        setLabelValue();
      }}
    >
      <Button className="download-btn sprite-img-before upload-file-btn">Choose File</Button>
      <p>{showDefaultText && `${defaultFileList[0].defaultValue}`}</p>
    </Upload>
  );
};

export default FileUploader;
