import React from 'react';
import './userimage.scss';

const UserImage = (props) => {
  const { UserImageData } = props;
  const { image } = UserImageData;
  return (
    <div className="user-image-wrap">
      <div className="img-wrap">
        <img src={`${process.env.PUBLIC_URL}${image}`} width="80px" alt="" />
      </div>
    </div>
  );
};

export default UserImage;
