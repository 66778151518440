
import React from 'react';
import './dashboard.scss';
import WelcomeCard from '../../components/WelcomeCard';
import Map from '../../components/map';
import Vision from '../../components/Vision';
import Pressure from '../../components/Pressure';
import Carousel from '../../components/Carousel';

const welcomeCardDetail = {
  image: '/images/doctor-img.png',
  name: 'Ashlynn',
  location: '554 South Pasadena',
  notify: 'Click on the button to notify the front desk',
  button: true,
};
const carouselList = [
  {
    comp: <Vision />,
  },
  {
    comp: <Pressure />,
  },
];

function Dashboard() {
  return (
    <div className="dashboard-wrap" id="dashboard">
      <div className="welcome-section">
        <WelcomeCard welcomeCardDetail={welcomeCardDetail} />
        <div className="mt-16 mb-24">
          <Map />
        </div>
        <section className="ocular-heath mt-24">
          <h2 className="mb-16">Ocular Health</h2>
          <div className="ocular-heath-wrap">
            <div className="mobile-only">
              <Carousel carouselList={carouselList} />
            </div>
            <div className="desktop-only">
              <div className="ocular-heath-desktop-wrap">
                <Vision />
                <Pressure />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Dashboard;
