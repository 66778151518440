import {
  React, useState, useCallback, useEffect,
} from 'react';
import onClickOutside from 'react-onclickoutside';
import { Layout, Menu } from 'antd';
import './sider.scss';
import { Link } from 'react-scroll';

const {
  Sider,
} = Layout;

function SideBar(props) {
  const { navOpen } = props;
  const [collapsed, setCollapsed] = useState(false);
  SideBar.handleClickOutside = () => {
    setCollapsed(!collapsed);
  };

  const toggle = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  useEffect(() => {
    toggle();
  }, [navOpen]);

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      breakpoint="lg"
      collapsedWidth="11.8rem"
      width="28.0rem"
      toggle={toggle}
      style={{
        height: '100vh',
        left: 0,
      }}
    >
      <div className="logo">
        <img src={`${process.env.PUBLIC_URL}/images/Logo.png`} alt="logo_img" />
      </div>
      <div className="mobile-logo">
        <img src={`${process.env.PUBLIC_URL}/images/m-logo.svg`} alt="logo_img" />
      </div>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
        <Menu.Item className="dashboard sprite-img-before" key="1">
          <Link
            activeClass="active"
            to="dashboard"
            spy
            smooth
            offset={-200}
            duration={500}
          >
            <span>Dashboard</span>
          </Link>
        </Menu.Item>
        <Menu.Item className="demographic sprite-img-before" key="2">
          <Link
            activeClass="active"
            to="demographic"
            spy
            smooth
            offset={-220}
            duration={500}
          >
            <span>Demographic</span>
          </Link>
        </Menu.Item>
        <Menu.Item className="insurance sprite-img-before" key="3">
          <Link
            activeClass="active"
            to="insurance"
            spy
            smooth
            offset={-120}
            duration={500}
          >
            <span>Insurance</span>
          </Link>

        </Menu.Item>
        <Menu.Item className="payment sprite-img-before" key="4">
          <Link
            activeClass="active"
            to="financial"
            spy
            smooth
            offset={-120}
            duration={500}
          >
            <span>Payments</span>
          </Link>

        </Menu.Item>
        <Menu.Item className="document sprite-img-before" key="5">
          <Link
            activeClass="active"
            to="document"
            spy
            smooth
            offset={-120}
            duration={500}
          >
            <span>Documents</span>
          </Link>

        </Menu.Item>
        <Menu.Item className="appointment sprite-img-before" key="6">
          <Link
            activeClass="active"
            to="appointment"
            spy
            smooth
            offset={-120}
            duration={500}
          >
            <span>Appointment</span>
          </Link>

        </Menu.Item>
        <Menu.Item className="prescription sprite-img-before" key="7">
          <Link
            activeClass="active"
            to="prescription"
            spy
            smooth
            offset={-120}
            duration={500}
          >
            <span>Prescription</span>
          </Link>

        </Menu.Item>
        <Menu.Item className="consent sprite-img-before" key="8">
          <Link
            activeClass="active"
            to="concent"
            spy
            smooth
            offset={-120}
            duration={500}
          >
            <span>Consent Forms</span>
          </Link>

        </Menu.Item>
      </Menu>
      <button className="close-nav" type="button" onClick={toggle}>
        close
      </button>
      <button type="button" className="log-out sprite-img-before"><span>Logout</span></button>
    </Sider>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => SideBar.handleClickOutside,

};

export default onClickOutside(SideBar, clickOutsideConfig);
