import React from 'react';
import './header.scss';

function Header(props) {
  const { navOpenAction, notificationOpenAction } = props;
  return (
    <div className="header">
      <div
        className="header-title"
        onClick={navOpenAction}
        onKeyDown={navOpenAction}
        role="button"
        tabIndex={0}
      >
        <img className="tab-only" src={`${process.env.PUBLIC_URL}/images/toggle-icon.png`} width="32" alt="logo_img" />
        <span>Focus App</span>
      </div>
      <div className="header-time">
        2:50 AM
      </div>
      <div
        className="notification-icon tab-only"
        onClick={notificationOpenAction}
        onKeyDown={notificationOpenAction}
        role="button"
        tabIndex={0}
      >
        <img src={`${process.env.PUBLIC_URL}/images/Notification-icon.png`} width="32" alt="logo_img" />
      </div>
    </div>
  );
}

export default Header;
