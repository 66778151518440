import React from 'react';
import Profile from '../../components/Profile';
import Accordion from '../../components/Accordion';
import Appointments from './component/Appointments';
import Medicine from './component/Medicine';
import Other from './component/Other';
import './notification.scss';

const panelList = [
  {
    data: {
      text: 'text1',
      header: 'Medicine',
      componentName: Medicine,
    },
  },
  {
    data: {
      text: 'text2',
      header: 'Appointments',
      componentName: Appointments,
    },
  },
  {
    data: {
      text: 'text3',
      header: 'Other',
      componentName: Other,
    },
  },
];

const profileDetails = {
  name: 'Ashlynn Westervelt',
  image: '/images/User-pic.png',
};

function Notification(props) {
  const { notificationOpen, notificationOpenAction } = props;
  return (
    <div className={`notification ${!notificationOpen && 'open'}`}>
      <div
        className="notification-title tab-only"
        onClick={notificationOpenAction}
        onKeyDown={notificationOpenAction}
        role="button"
        tabIndex={0}
      >
        Notifications
      </div>
      <div className="notification-inn-card">
        <div className="notification-inn-card-in" id="notification-custom-scroll">
          <div className="notification-profile">
            <Profile profileDetails={profileDetails} />
            <div className="notification-link">
              <span className="badge" />
            </div>
          </div>
          <div className="reminder-header">
            <p className="reminder-title">
              Reminder
            </p>
            <p>
              <span>Today</span>
              <span className="calender-icon sprite-img-before" />
            </p>
          </div>
          <Accordion panelList={panelList} />
        </div>
        <div className="call-icon" />
      </div>
    </div>
  );
}

export default Notification;
