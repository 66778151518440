import React from 'react';
import './table.scss';

const Table = (props) => {
  const { tableContent } = props;
  const { body, head } = tableContent;

  return (
    <div className="table-wrap">
      <table>
        <thead>
          {head.map((item, i) => (
            <tr key={i}>
              {Object.keys(item).map((key, index) => <th key={index}>{item[key]}</th>)}
            </tr>
          ))}

        </thead>
        <tbody>
          {body.map((item, i) => (
            <tr key={i}>
              {Object.keys(item).map((key, index) => <td key={index}>{item[key]}</td>)}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
