export const GET_DOCTORS = 'GET_DOCTORS';
export const GET_DOCTORS_SCHEDULES = 'GET_DOCTORS_SCHEDULES';
export const GET_DOCTORS_APPOINTMENTS = 'GET_DOCTORS_APPOINTMENTS';
export const GET_SELECTED_DOCTOR_APPOINTMENT = 'GET_SELECTED_DOCTOR_APPOINTMENT';
export const GET_SELECTED_DOCTOR_SCHEDULE = 'GET_SELECTED_DOCTOR_SCHEDULE';
export const GET_SELECTED_DOCTORS_DATA = 'GET_SELECTED_DOCTORS_DATA';
export const GET_SELECTED_DOCTORS_FREE_SLOTS = 'GET_SELECTED_DOCTORS_FREE_SLOTS';

export const ADD_DOCTOR = 'ADD_DOCTOR';
export const SET_SELECTED_DOCTOR = 'SET_SELECTED_DOCTOR';
export const UPDATE_DOCTOR = 'UPDATE_DOCTOR';

export const ADD_APPOINTMENT = 'ADD_APPOINTMENT';
export const SET_SELECTED_APPOINTMENT = 'SET_SELECTED_APPOINTMENTS';
export const SET_SELECTED_DOCTORS_SCHEDULER_DATA = 'SET_SELECTED_DOCTORS_SCHEDULER_DATA';
export const SET_SELECTED_DOCTORS_FREE_SLOTS = 'SET_SELECTED_DOCTORS_FREE_SLOTS';
export const RESET_APPOINTMENT = 'RESET_APPOINTMENT';

export const RESCHEDULE_APPOINTMENT = 'RESCHEDULE_APPOINTMENT';
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENTS';
export const UPDATE_APPOINTMENT_POSITION = 'UPDATE_APPOINTMENT_POSITION';
export const UPDATE_APPOINTMENT_DETAIL = 'UPDATE_APPOINTMENT_DETAIL';
export const UPDATE_APPOINTMENT_STATUS = 'UPDATE_APPOINTMENT_STATUS';
export const UPDATE_APPOINTMENT_ID = 'UPDATE_APPOINTMENT_ID';
export const REMOVE_APPOINTMENT = 'REMOVE_APPOINTMENT';

export const SET_ERROR = '@schedular/SET_ERROR';
export const SET_LOADING = '@schedular/SET_LOADING';

export const getDoctorsData = () => ({
  type: GET_DOCTORS,
});

export const getDoctorsSchedulesData = () => ({
  type: GET_DOCTORS_SCHEDULES,
});

export const getDoctorsAppointmentsData = () => ({
  type: GET_DOCTORS_APPOINTMENTS,
});

export const setSelectedDoctor = (doctorId) => ({
  type: SET_SELECTED_DOCTOR,
  doctorId,
});

export const addDoctor = (payload = {}) => ({
  type: ADD_DOCTOR,
  payload,
});

export const updateDoctor = (payload) => ({
  type: UPDATE_DOCTOR,
  payload,
});

export const setSelectedAppointment = (doctorId) => ({
  type: SET_SELECTED_APPOINTMENT,
  doctorId,
});

export const setSelectedDoctorsSchedulerData = (data) => ({
  type: SET_SELECTED_DOCTORS_SCHEDULER_DATA,
  data,
});

export const setSelectedDoctorsFreeSlots = (data) => ({
  type: SET_SELECTED_DOCTORS_FREE_SLOTS,
  data,
});

export const addAppointment = (payload = {}) => ({
  type: ADD_APPOINTMENT,
  payload,
});

export const updateAppointment = (payload) => ({
  type: UPDATE_APPOINTMENT,
  payload,
});

export const updateAppointmentPosition = (payload) => ({
  type: UPDATE_APPOINTMENT_POSITION,
  payload,
});

export const rescheduleAppointment = (payload, prevPayload) => ({
  type: RESCHEDULE_APPOINTMENT,
  payload,
  prevPayload,
});

export const resetAppointment = (payload, prevPayload) => ({
  type: RESET_APPOINTMENT,
  payload,
  prevPayload,
});
export const updateAppointmentDetail = (payload) => ({
  type: UPDATE_APPOINTMENT_DETAIL,
  payload,
});

export const updateAppointmentStatus = (payload) => ({
  type: UPDATE_APPOINTMENT_STATUS,
  payload,
});

export const updateAppointmentId = (payload) => ({
  type: UPDATE_APPOINTMENT_ID,
  payload,
});

export const removeAppointment = (payload) => ({
  type: REMOVE_APPOINTMENT,
  payload,
});

export const getSelectedDoctorsSchedule = (payload) => ({
  type: GET_SELECTED_DOCTOR_SCHEDULE,
  payload,
});

export const getSelectedDoctorsAppointment = (payload) => ({
  type: GET_SELECTED_DOCTOR_APPOINTMENT,
  payload,
});

export const getSelectedDoctorsData = (payload) => ({
  type: GET_SELECTED_DOCTORS_DATA,
  payload,
});

export const getSelectedDoctorsFreeSlots = (payload = {}) => ({
  type: GET_SELECTED_DOCTORS_FREE_SLOTS,
  payload,
});

export const setError = (error) => ({
  type: SET_ERROR,
  error,
});

export const setLoading = (flag) => ({
  type: SET_LOADING,
  flag,
});
