const UiRoutes = {
  home: '/',
  home2: '/2',
};

const formName = {
  patientForm: 'patient-form',
  providerForm: 'provider-form',
  usersForm: 'users-form',
  usersProfileForm: 'users-profile-form',
  locationsForm: 'locations-form',
  feePreferencesGeneralForm: 'fee-preferences-general-form',
  billingForm: 'billing-form',
  credentialForm: 'credential-form',
  patientCaseForm: 'patient-case-form',
  patientAuthorizationForm: 'patient-authorization-form',
};

const formId = {
  PATIENT_DEMOGRAPHIC: 'patient-demographic',
  LOCATION_GENERAL: 'location-general',
  PROVIDER: 'provider',
  USER: 'setting-user-form',
  E_PRESCRIPTION: 'setting-provider-ePrescription',
  billing: 'provider-billing-form',
  REQUEST_NEW_PAYER: 'request-new-payer',
  EDIT_PATIENT_DEMOGRAPHIC: 'patient-edit-demographic',
  ADD_PATIENT: 'add-patient',
  ADD_LOCATION: 'add-location',
  EDIT_LOCATION_GENERAL: 'location-edit-general',
  ADD_PROVIDER: 'add-provider',
  EDIT_PROVIDER_GENERAL: 'settings-provider-edit-general',
  ADD_USER: 'setting-add-user',
  EDIT_USER_GENERAL: 'setting-user-edit-general',
  ADD_E_PRESCRIPTION: 'setting-provider-add-ePrescription',
  EDIT_E_PRESCRIPTION: 'setting-provider-edit-ePrescription',
  ADD_BILLING: 'provider-add-billing',
  EDIT_BILLING: 'provider-edit-billing',
  ADD_PAYERHOLD: 'provider-add-payerHold',
  EDIT_PAYERHOLD: 'provider-edit-payerHold',
  MEDICATION_FORM: 'doctor-encounter-medication',
  PROCEDURE_FORM: 'doctor-encounter-procedure',
  EDIT_PROCEDURE_FAVOURITE: 'doctor-encounter-procedure-favourite-edit',
  UPLOAD_DOCUMENT: 'patient-document-upload',
  ADD_NEW_CPT: 'add_new_cpt',
  ADD_NEW_CASE: 'add_new_case',
  UPDATE_CASE: 'update_case',
  EDIT_AUTHORIZATION: 'edit-authorization',
  ADD_AUTHORIZATION: 'add-authorization',
  ADD_ENCOUNTER: 'add-encounter',
  EDIT_PROVIDER_CREDENTIALS: 'provider-edit-credentials',
  ADD_PROVIDER_CREDENTIALS: 'provider-add-credentials',
  ADD_PAYER: 'add-payer',
  ADD_EXAM_ROOM: 'add-exam-room',
  EDIT_EXAM_ROOM: 'edit-exam-room',
  ADD_CANCELLATION_REASON: 'add-cancellation-reason',
  EDIT_CANCELLATION_REASON: 'edit-cancellation-reason',
  ADD_VISIT_TYPE: 'add-visit-type',
  EDIT_VISIT_TYPE: 'edit-visit-type',
  ADD_EXAM_TYPE: 'add-exam-type',
  EDIT_EXAM_TYPE: 'edit-exam-type',
  ADD_RESOURCE: 'add-resource',
  EDIT_RESOURCE: 'edit-resource',
  ADD_BLOCKOUT: 'add-block-out',
  EDIT_BLOCKOUT: 'edit-block-out',
  ADD_TEMPLATE: 'add-template',
  EDIT_TEMPLATE: 'edit-template',
  ADD_CONTRACT_GENERAL: 'add-contract',
  EDIT_CONTRACT: 'edit-contract',
  EDIT_PAYER: 'edit-payer',
  ADD_PERSON: 'add-person',
  EDIT_PERSON: 'edit-person',
  ADD_RESPONSIBLE_PARTY: 'add-responsible-party',
  EDIT_RESPONSIBLE_PARTY: 'edit-responsible-party',
  ADD_SECONDARY_POLICY: 'add-secondary-policy',
  EDIT_INSURANCE_PROFILE: 'edit-insurance-profile',
  ALLERGIES: 'allergies',
  LOGIN_FORM: 'login-form',
  RESET_PASSWORD_FORM: 'reset-password-form',
  SCHEDULAR_ADD_APPOINTMENT_PATIENT: 'schedular-add-appointment-patient',
  ADD_APPOINTMENT: 'add-appointment',
  FORGOT_PASSWORD_FORM: 'forgot-password-form',
  ADD_TERM_GENERAL: 'contract-add-term-general',
  EDIT_TERM_GENERAL: 'contract-edit-term-general',
  BOOK_APPOINTMENT_FILTER: 'book-appointment-filter',
  UPDATE_PROVIDER_FEE_PREFERENCE_GENERAL: 'provider-fee-preference-general',
  CC_HPI: 'cc_hpi',
  PATIENT_REFRACTION: 'patient-refraction',
  ADD_TECHNICIAN_EXAM_VISION: 'technician-exam-vision-add',
  TECHNICIAN_EXAM_VITALS: 'technician-exam-vitals',
  ADD_TECHNICIAN_EXAM_PRESSURE: 'technician-exam-pressure-add',
  SCHEDULAR_SETTINGS_BASICS: 'schedular-settings-basics',
  PROVIDER_FEE_PREFERENCES_EXCEPTION: 'provider-fee-preferences-exception',
  UPDATE_PROVIDER_FEE_PREFERENCES_EXCEPTION: 'update-provider-fee-preferences-exception',
  ADD_FAVOURITE: 'add-favourite',
  ADD_FOLDER: 'add-folder',
  ADD_DOCTOR_PRESSURE: 'doctor-pressure-add',
  EDIT_DOCTOR_TECHNICIAN_PRESSURE: 'doctor-pressure-edit',
  EDIT_DOCTOR_TECHNICIAN_VISION: 'doctor-vision-edit',
  DELETE_DOCTOR_PRESSURE: 'doctor-pressure-remove',
  DELETE_DOCTOR_VISION: 'doctor-vision-remove',
  ADD_DOCTOR_GOAL_PRESSURE: 'doctor-pressure-goal-add',
  ADD_DOCTOR_VISION: 'doctor-vision-add',
  TECHNICIAN_MOTILITY: 'technician-motility',
  ASSESSMENT_AND_PLAN: 'assessment-and-plan',
  PMFSHX_NO_REVIEWED: 'pmfshx-no-reviewed',
  CHIEF_COMPLAINT_DOCTOR: 'chief-complaint-doctor',
  DOCTOR_FOLLOW_UP_FORM: 'doctor-follow-up-form',
  DOCTOR_FOLLOW_UP_FORM_ORDER_SET: 'doctor-follow-up-form-order-set',
  OUTSIDE_TESTING: 'doctor-outside-testing',
  EDIT_PATIENT_CARD: 'check-in-card',
  EDIT_PATIENT_CARD_USER_PROFILE: 'user-profile-check-in',
  DROPDOWN_HPI: 'Dropdown-hpi',
  ADD_ADDRESS_BOOK: 'add-address-book',
  UPDATE_ADDRESS_BOOK: 'update-address-book',
  ADD_INCIDENT: 'add-incident',
  NEW_CLAIM: 'add-new-claim',
  NEW_REMITTANCE_BATCH: 'add-new-remittance-batch',
  TASK_REASSIGN: 'task-reassign',
  REMITTANCE_INSURANCE_WORK_LIST: 'remittance-insurance-work-list',
  TECHNICIAN_COOLTONE_ADD_UPDATE: 'technician-cooltone-add-and-update',
  ADD_CUTERA: 'add-cutera',
  MARKUP_FOR_FOLLOWUP: 'markup-for-followup',
  MICRO_NEEDLING: 'micro-needling',
  EDIT_TASK_COMMENT: 'edit-task-comment',
  ADD_ULTHERAPY: 'Ultherapy-form',
  COOL_SCULPT: 'cool-sculpt-add',
  LILLY_LEE_HPI: 'lilly-lee-hpi',
  TECHNICIAN_EYELIDS_FORM: 'Technician-Eyelids-form',
  PAYER_AGING_ADD: 'add-payer-aging',
  PAYER_AGING_UPDATE: 'update-payer-aging',
  ADD_SIMPLE_CHARGE: 'add-simple-charge',
  SUSPENSE_ASSIGN_TO: 'suspense-assigned-to',
  UPDATE_PROMOTION: 'update-inventory-promotion',
  ADD_PROMOTION: 'add-inventory-promotion',
  ADD_BATCH_TO_RECEIVE: 'add-batch-to-inventory',
  ADD_PATIENT_ADJUSTMENT: 'add-patient-adjustment',
  Add_INSURANCE_ADJUSTMENT: 'add-insurance-adjustment',
};

const listIds = {
  SETTINGS_USERS: 'SETTINGS_USERS',
  PATIENTS_DOCUMENTS: 'PATIENTS_DOCUMENTS',
  SETTINGS_PROVIDER_E_PRESCRIPTION: 'SETTINGS_PROVIDER_E_PRESCRIPTION',
  PROVIDER_CREDENTIALS: 'SETTING_PROVIDER_CREDENTIALS',
  AUTHORIZATION_LIST: 'LIST_AUTHORIZATIONS',
  SETTING_PAYERS: 'SETTING_PAYERS',
  SETTING_PROVIDER_BILLING: 'SETTING_PROVIDER_BILLING',
  GET_PATIENT_DOCUMENT: 'GET_PATIENT_DOCUMENT',
  SETTING_PROVIDER_PAYERHOLD: 'SETTING_PROVIDER_PAYERHOLD',
  SETTING_GET_CPT_PAYER_MAPPING: 'SETTING_GET_CPT_PAYER_MAPPING',
  SETTING_GET_ICD_CODE_MAPPING: 'SETTING_GET_ICD_CODE_MAPPING',
  SETTING_DEL_ICD_CODE_MAPPING: 'SETTING_DEL_ICD_CODE_MAPPING',
  DELETE_PATIENT_DOCUMENT: 'DELETE_PATIENT_DOCUMENT',
  DELETE_MASTER_DATA: 'DELETE_MASTER_DATA',
  LIST_CASES: 'LIST_CASES',
  LOCATION_EXAMROOM_LIST: 'EXAMROOM_LIST',
  GET_PAYER: 'GET_PAYER',
  TECHNICIAN_CURRENT_MEDICATIONS: 'TECHNICIAN_CURRENT_MEDICATIONS',
  SCHEDULAR_SETTINGS_BLACK_OUT_LIST: 'SCHEDULAR_SETTINGS_BLACKOUT_LIST',
  SCHEDULAR_MONTHLY_SCHEDULES_LIST: 'SCHEDULAR_MONTHLY_SCHEDULES_LIST',
  CANCELLATION_REASON: 'CANCELLATION_REASON',
  SCHEDULAR_SETTINGS_VISIT_TYPE: 'SCHEDULAR_SETTINGS_VISIT_TYPE',
  SCHEDULAR_SETTINGS_EXAM_TYPE: 'SCHEDULAR_SETTINGS_EXAM_TYPE',
  SCHEDULAR_SETTINGS_RESOURCE: 'SCHEDULAR_SETTINGS_RESOURCE',
  SCHEDULAR_SETTINGS_TEMPLATE_LIST: 'SCHEDULAR_SETTINGS_TEMPLATE_LIST',
  CONTRACT: 'CONTRACT',
  PATIENT_ENCOUNTER_LIST: 'PATIENT_ENCOUNTER_LIST',
  GET_HISTORY_ENCOUNTER_LIST: 'GET_HISTORY_ENCOUNTER_LIST',
  ENCOUNTER_DETAIL_PROCEDURE: 'ENCOUNTER_DETAIL_PROCEDURE',
  DOCTOR_PROCEDURES: 'DOCTOR_PROCEDURES',
  DOCTOR_PROCEDURES_ORDER_SET: 'DOCTOR_PROCEDURES_ORDER_SET',
  DOCTOR_IMAGING_HISTORY: 'DOCTOR_IMAGING_HISTORY',
  CLAIMS_LIST: 'PATIENTS_BILLS',
  CLAIMS_DETAILS_LIST: 'PATIENTS_BILLS_DETAILS_LIST',
  DOCTOR_ENCOUNTER_REVIEW: 'DOCTOR_ENCOUNTER_REVIEW',
  TECHNICIAN_ENCOUNTER_REVIEW: 'TECHNICIAN_ENCOUNTER_REVIEW',
  ALLERGIES: 'allergies',
  USER_PROFILE_TYPE: 'user-profile-type',
  PATIENT_BILL_STATEMENT: 'BILL_STATEMENT',
  APPOINTMENT_CARDS_LIST: 'patient-appointment-cards-list',
  SCHEDULER_UPDATE_STATUS: 'SCHEDULER_UPDATE_STATUS',
  LIST_APPOINTMENTS: 'LIST_APPOINTMENTS',
  PATIENT_ALLERGIES: 'patient-allergies',
  DELETE_PATIENT_ALLERGIES: 'delete-patient-allergies',
  DELETE_ALL_PATIENT_ALLERGIES: 'delete-all-patient-allergies',
  CONTRACT_TERMS_EXCEPTION: 'EXCEPTION_LIST',
  CONTRACT_TERMS_MODIFIER: 'CONTRACT_TERMS_MODIFIER',
  PMFSHX_LIST: 'pmfshx-listing',
  PATIENT_QUESTIONNAIRES_MASTER_PMFSHX: 'patient-questionnaire_master',
  DOCTOR_QUESTIONNAIRES_MASTER: 'doctor-questionnaire_master',
  BILL_STATEMENT_LIST: 'BILL_STATEMENT_LIST',
  PATIENT_REFRACTION_TYPE: 'PATIENT_REFRACTION_TYPE',
  PATIENT_WEARING_DATA: 'PATIENT_WEARING_DATA',
  PATIENT_REFRACT_DATA: 'PATIENT_REFRACT_DATA',
  ENCOUNTER_REFRACT_DATA: 'ENCOUNTER_REFRACT_DATA',
  PATIENT_REFRACT_HISTORY: 'PATIENT_REFRACT_HISTORY',
  PATIENT_UPDATE_REFRACTION: 'PATIENT_UPDATE_REFRACTION',
  PATIENT_DELETE_REFRACTION: 'PATIENT_DELETE_REFRACTION',
  PATIENTS_LIST_APPOINTMENTS: 'LIST_APPOINTMENTS',
  SAVE_PMFSHX: 'save-pmfshx',
  SAVE_ROS: 'save-ros',
  SAVED_PMFSHX: 'saved-pmfshx',
  PROVIDER_FEE_PREFERENCES_EXCEPTION: 'PROVIDER_FEE_PREFERENCES_EXCEPTION',
  ROS_QUESTIONNAIRE_ENUM: 'ros-questionnaire-enum',
  ROS_QUESTIONNAIRE: 'ros-questionnaire',
  AUTHORIZATIONS_QUESTIONNAIRE: 'authorizations-questionnaire',
  GET_AUTHORIZATIONS_QUESTIONNAIRE: 'get-authorizations-questionnaire',
  UPDATE_AUTHORIZATIONS_QUESTIONNAIRE: 'update-authorizations-questionnaire',
  SAVED_ROS: 'saved-ros',
  OCULAR_PROBLEM_LIST: 'ocular-problem-list',
  DELETE_OCULAR_PROBLEM: 'delete-ocular-problem',
  DELETE_OCULAR_PROBLEM_FROM_TABLE: 'delete-ocular-problem-from-table',
  ADD_OCULAR_PROBLEM: 'add-ocular-problem',
  ENCOUNTER_LIST: 'encounter-list',
  PRESSURE_GRAPH: 'pressure-graph',
  VISION_GRAPH: 'vision-graph',
  GET_SENSORIMOTOR_SECTIONS: 'GET_SENSORIMOTOR_SECTIONS',
  SENSORIMOTOR_SECTION_1: 'SENSORIMOTOR_SECTION_1',
  SENSORIMOTOR_SECTION_2: 'SENSORIMOTOR_SECTION_2',
  SENSORIMOTOR_SECTION_3: 'SENSORIMOTOR_SECTION_3',
  SLIT_EXAM_ENUM: 'slit-exam-enum',
  FUNDUS_EXAM_ENUM: 'fundus-exam-enum',
  SLIT_EXAM_SELECT: 'slit-exam-select',
  FUNDUS_EXAM_SELECT: 'fundus-exam-select',
  SAVE_SLIT_EXAM: 'save-slit-exam',
  SAVE_FUNDUS_EXAM: 'save-fundus-exam',
  GET_SLIT_EXAM: 'get-slit-exam',
  GET_FUNDUS_EXAM: 'get-fundus-exam',
  OCULAR_FAVOURITES_AND_FOLDERS: 'ocular-favourites-and-folders',
  OCULAR_GENERAL_FAVOURITES_AND_FOLDERS: 'ocular-general-favourites-and-folders',
  OCULAR_FAVOURITES_OF_A_FOLDER: 'ocular-favourites-of-a-folder',
  DELETE_FOLDER: 'delete-folder',
  IMAGES_TEST_TYPES: 'images-test-types',
  GET_BUNDLED_IMAGES: 'get-bundled-images',
  GET_BUNDLED_IMAGES_FOR_COMPARISON: 'get-bundled-images-for-comparison',
  UPDATE_IMAGE_OSOD_TYPE: 'update-image-osod-type',
  UPLOAD_ENCOUNTER_IMAGES: 'upload-encounter-images',
  UPLOAD_DRAW_DEFAULT_IMAGES: 'upload-draw-default-images',
  UPDATE_ENCOUNTER_IMAGE_TEST_TYPE: 'update-encounter-image-test-type',
  ALL_NORMAL: 'all-normal',
  PATIENT_ENCOUNTER_LIST_DATA: 'PATIENT_ENCOUNTER_LIST_DATA',
  PRINT_REFRACT: 'print-refract',
  DOCTOR_ENCOUNTER_DETAILS: 'doctor-encounter',
  EXPORT_CONTRACT: 'export-contract',
  ROS_REVIEWED: 'ros-reviewed',
  DEFAULT_SLIT_VALUES: 'default-slit-values',
  DEFAULT_FUNDUS_VALUES: 'default-fundus-values',
  COPY_PREVIOUS_SLIT: 'copy-previous-slit',
  COPY_PREVIOUS_FUNDUS: 'copy-previous-fundus',
  CARE_TEAM_LIST: 'care-team-list',
  CARE_TEAM_DELETE: 'care-team-delete',
  ADD_PROVIDER_CARE_TEAM: 'add-provider-care-team',
  DELETE_ADDRESS_BOOK_ITEM: 'delete-address-book-item',
  GET_REFERRAL_LIST: 'get-referral-list',
  SAVE_ORDER_SET: 'save-order-set',
  UPDATE_ORDER_SET: 'update-order-set',
  GET_ORDER_SET_BY_ID: 'get-order-set-id',
  APPLY_ORDER_SET: 'apply-order-set',
  GET_PATIENT_ENCOUNTER: 'get-patient-encounter',
  ADD_PATIENT_ENCOUNTER: 'add-patient-encounter',
  UPDATE_PATIENT_ENCOUNTER: 'update-patient-encounter',
  ENCOUNTER_DETAIL_BY_ID: 'encounter-detail-by-id',
  SIGN_AND_BILL_LIST: 'sign-and-bill-list',
  ENM_CODE: 'enm-codes',
  SIGN_AND_BILL: 'sign-and-bill',
  GET_CLAIMS_BY_CLAIM_ID: 'get-claims-by-claim-id',
  CLAIM_DETAILS: 'patient-claim-details',
  GET_MODIFIER_OPTIONS: 'get-modifier-options',
  GET_ACTIVITY_LOGS: 'get-activity-logs',
  GET_PATIENT_NOTES: 'get-patient-notes',
  ADD_PATIENT_NOTES: 'add-patient-notes',
  PROCESSED_CLAIMS_FOR_MASS_REBILL: 'processed-claims-for-mass-rebill',
  LIST_PRODUCT: 'list-product',
  DATA_LIST_CATEGORIES: 'data-list-categories',
  IMPORT_FRAMES_DATA: 'import-frames-data',
  UNPOSTED_INVOICE_LIST: 'unposted-invoice-list',
  SEARCH_INVOICE_LIST: 'search-invoice-list',
  DISPENSE_PROMOTION_LIST: 'dispense-promotion-list',
  OPTICAL_REPRINT_LABELS: 'OPTICAL_REPRINT_LABELS',
  SEARCH_INVENTORY_BARCODE: 'SEARCH_INVENTORY_BARCODE',
  BUSINESS_ENTITIES: 'BUSINESS_ENTITIES',
  BUSINESS_ENTITY_CREDENTIALS: 'BUSINESS_ENTITY_CREDENTIALS',
  REFERRAL_SOURCE_TABLE: 'REFERRAL_SOURCE_TABLE',
  BUSINESS_ENTITY_BILLING_NDC: 'BUSINESS_ENTITY_BILLING_NDC',
  BUSINESS_ENTITY_BILLING_NDC_PROCEDURE: 'BUSINESS_ENTITY_BILLING_NDC_PROCEDURE',
  NEW_REMITTANCE_BATCH: 'NEW_REMITTANCE_BATCH',
  ALL_TASK_TABLE: 'all-task-table',
  PATIENT_TASK_TABLE: 'patient-task-table',
  DOCTOR_DETAILS: 'doctor-details',
  POSTED_CLAIMS_TABLE: 'posted-claims-table',
  REMITTANCE_BATCH_DETAILS: 'remittance-batch-details',
  REMITTANCE_BATCH_MARK_AS_BLANK: 'remittance-batch-mark-as-blank',
  PMFSHX_REVIEW: 'pmfshx-review',
  INSURANCE_PAYMENT_ENTERED_LINE_ITEMS: 'insurance-payment-line-item',
  PATIENT_PAYMENT_ENTERED_LINE_ITEMS: 'patient-payment-line-item',
  GET_INSURANCE_PAYMENT: 'insurance-payment',
  EDIT_TASK: 'edit-task',
  MARKUP_FOR_FOLLOWUP: 'markup-for-followup',
  REMOVE_POSTED_CHECK: 'remove-posted-remittance',
  UPDATE_MEDICATION: 'update-medication',
  GET_REMITTANCE_PAYMENT_DETAILS: 'get-remittance-payment-details',
  INSURANCE_PAYMENT_COMPLETE_CHECK: 'insurance-payment-complete-check',
  FINANCIAL_LIST: 'financial-balance-list',
  FINANCIAL_CHARGE_LIST: 'financial-charge-list',
  SET_PATIENT_PAYMENT: 'set-patient-payment',
  FINANCIAL_INSURANCE_PAYMENT_LIST: 'financial-insurance-payment-list',
  POST_INSURANCE_WORKLIST_LINE_ITEMS: 'post-insurance-worklist-line-items',
  CREATE_DISPENSE_INVOICE: 'create-dispense-invoice',
  POST_UNPOSTED_INVOICE: 'post-unposted-invoice',
  DELETE_UNPOSTED_INVOICE: 'delete-unposted-invoice',
  PATIENT_SERVICE_LINE_ITEM: 'patient-service-line-item',
  ADD_PRODUCT_IN_INVENTORY: 'add-product-in-inventory',
  MARK_COMPLETE_WORKLIST: 'mark-complete-worklist',
  DISCARD_INVENTORY_ITEM: 'discard-inventory-item',
  RECALL_INVENTORY_ITEM: 'recall-inventory-item',
  EXPORT_INVENTORY_ITEM: 'export-inventory-item',
  GET_APPOINTMENT_DETAILs: 'get-appointment-details',
  GET_PATIENT_DETAILS: 'GET_PATIENT_DETAILS',
  GET_PATIENT_ALLERGY_LIST: 'GET_PATIENT_ALLERGY_LIST',
};

const enums = {
  PHONE_TYPE: 'PhoneType',
  COUNTRY: 'Country',
  STATE: 'State',
  RELATION: 'Relation',
  REFERRAL: 'ReferralSource',
  REGION: 'Region',
  GENDER: 'Gender',
  BUSINESS_ENTITY: 'BusinessEntity',
  PLACE_OF_SERVICE: 'Placeofservice',
  FACILITY_TYPE: 'FacilityType',
  FACILITY_SUB_TYPE: 'FacilitySubType',
  LOCALITY: 'Locality',
  LOCATION_TYPE: 'LocationType',
  BILLING_NAME: 'Billing Name',
  SCHEDULE: 'Schedule',
  TIMEZONE: 'Timezone',
  STATEMENT_METHOD: 'StatementMethod',
  PREFERRED_CONTACT_METHOD: 'PreferredContactMethod',
  LANGUAGE: 'Language',
  PATIENT_DOCUMENT_CATEGORY: 'PatientDocumentCategory',
  CASE_TYPE: 'CaseType',
  DISCHARGE_STATUS: 'DischargeStatus',
  ACCIDENT_TYPE: 'AccidentType',
  ADJUSTOR: 'Adjuster',
  LIABLE_PARTY: 'LiabelParty',
  INVITATION_SEND_TYPE: 'InvitationSendType',
  DEFAULT_APP: 'DefaultApp',
  CREDENTIAL_TYPE: 'CredentialType',
  SPECIALTY: 'Speciality',
  PROVIDER_SPECIALTY: 'ProviderSpeciality',
  INSURANCE_PAYER_TYPE: 'InsurancePayerType',
  SUBMISSION_METHOD: 'SubmissionMethod',
  SUB_SPECIALITY: 'SubSpeciality',
  PATIENT_AGE_LIMIT: 'PatientAgeLimit',
  PRIMARY_JOB_FUNCTION_TYPE: 'PrimaryJobFunctionType',
  INSURANCE_POLICY_LEVEL: 'InsurancePolicyLevel',
  POLICY_TYPE: 'PolicyType',
  SCHEDULAR_PROVIDER: 'SchedulerProvider',
  CONTRACT_PANEL_STATUS: 'ContractPanelStatus',
  CONTRACT_TYPE: 'ContractType',
  CANCELLATIONS: 'Cancellations',
  CONTRACT_TERM_TYPE: 'ContractTermType',
  CAPITATION_PAYMENT_ARRANGEMENT: 'CapitationPaymentArrangement',
  CONTRACT_TERM_POLICY_TYPE: 'ContractTermPolicyType',
  CONTRACT_TERM_PLAN: 'ContractTermPlan',
  FEE_FOR_SERVICES_PAYMENT_ARRANGEMENT: 'FeeForServicesPaymentArrangement',
  CONTRACT_TERM_PLACES_OF_SERVICE: 'ContractTermPlacesOfService',
  CONTRACT_TERM_SPECIFIC_PLACES_OF_SERVICE: 'Placeofservice',
  CONTRACT_TERM_POLICY_OF_CATEGORY: 'ContractTermPolicyTypeCategory',
  CONTRACT_TERM_PLAN_TYPE_CATEGORY: 'ContractTermPlanTypeCategory',
  CONTRACT_TERM_YEAR: 'ContractTermYear',
  CONTRACT_TERM_LOCALITY: 'ContractTermLocality',
  FEE_FOR_SERVICE_VALUE_IF_NO_PROCEDURE: 'FeeForServiceValueIfNoProcedure',
  APPOINTMENT_STATUS: 'AppointmentStatus',
  PREFERRED_LANGUAGE: 'PreferredLanguage',
  TECHNICIAN_VISION_EXAM: 'TechnicianVisionExam',
  CONTRACT_TERM_EXCEPTION_TYPE: 'ContractTermExceptionType',
  CONTRACT_TERM_EXCEPTION_TYPE_VALUE_TYPE: 'ContractTermExceptionTypeValueType',
  PATIENT_QUESTIONNAIRES_MASTER_PMFSHX: 'PatientQuestionnaresMasterPMFSHX',
  CAPITATION_ADJUST_CLAIM_REASON: 'CapitationAdjustClaimReason',
  CONTRACT_TERM_FEE_SCHEDULE_TYPE: 'ContractTermFeeScheduleType',
  MODIFIER: 'Modifier',
  FACILITY_TYPE_EXCEPTION: 'Facility Type',
  VALUE_TYPE: 'ValueType',
  PATIENT_REFRACTION_TYPE: 'PatientRefractionType',
  PATIENT_QUESTIONNAIRES_MASTER_ROS: 'PatientQuestionnaresMasterROS',
  PATIENT_ENCOUNTER_EXAM_TYPE: 'PatiantEncounterExamType',
  TECHNICIAN_PRESSURE_EXAM: 'TechnicianPressureExam',
  ENCOUNTER_PROCEDURE_STATUS: 'EncounterProcedureStatus',
  SENSORIMOTOR_FIRST_SECTION_TYPE: 'SensorimotorFirstSectionType',
  SENSORIMOTOR_SECOND_SECTION_DD1: 'SensorimotorSecondSectionDD1',
  SENSORIMOTOR_SECOND_SECTION_DD2: 'SensorimotorSecondSectionDD2',
  SENSORIMOTOR_FIRST_SECTION_DDS: 'SensorimotorIOSRDropdown',
  PATIENT_QUESTIONNAIRES_MASTER_SLIT_EXAM: 'PatientQuestionnaresMasterSLITLAMPEXAM',
  PATIENT_QUESTIONNAIRES_MASTER_FUNDUS_EXAM: 'PatientQuestionnaresMasterFUNDUSEXAM',
  PROCEDURE_QUESTIONNAIRES: 'Procedurequestionnaires',
  PREFERRED_METHOD: 'PreferredContactMethod',
  REFRACT_MANIFEST: 'RefractionManifest',
  MANUFACTURER: 'Manufacturer',
  BRAND: 'Brand',
  ADDRESS_BOOK_PROVIDER_TYPE: 'AddressBookProviderType',
  ADDRESS_BOOK_REGION: 'AddressBookRegion',
  PROVIDER_TYPE: 'AddressBookProviderType',
  CLAIMS_DELIVERY_METHOD: 'ClaimDeliveryMethod',
  COLLECTION_GROUPING: 'CollectionGrouping',
  HOW_DID_YOU_HEAR_ABOUT_US: 'HowDidYouHearAboutUs',
  LOCATION_SEARCH_RADIUS: 'LocationSearchRadius',
  DELIVERY_METHOD: 'ClaimDeliveryMethod',
  ERROR_TYPE: 'ClaimsErrorType',
  AGING_FILTER: 'CollectionGroupAging',
  TASK_CATEGORIES: 'TaskCategories',
  TASK_SUB_CATEGORIES: 'TaskSubCategories',
  TASK_PRIORITY_IDS: 'TaskPriorityIds',
  TASK_STATUS_IDS: 'TaskStatusIds',
  CUTERA_AREA_TREATED: 'CuteraLaserGenesisAreasTreated',
  CUTERA_SETTINGS_KJ: 'CuteraLaserIPLSettingsKJ',
  CUTERA_SPOT_SIZE: 'CuteraLaserSettingsSpotSize',
  NDYAG: 'Ndyag',
  CLAIM_FOLLOW_IN: 'ClaimFollowupIn',
  MICRO_NEEDLING_DEPTH: 'MicroNeedling-Depth',
  MICRO_NEEDLING_SPEED: 'MicroNeedling-Speed',
  MICRO_NEEDLING_ADDITIVE_AGENT: 'MicroNeedling-Additive Agent',
  PATIENT_PAYMENT_TYPE: 'PatientPaymentType',
  PATIENT_PAYMENT_CARD_TYPE: 'PatientPaymentCardType',
  EYELIDS_DERMATOCHALASIS: 'EyeLids-Dermatochalasis',
  EYELIDS_ECTROPION: 'EyeLids-Ectropion',
  EYELIDS_LESIONS: 'EyeLids-Lesions',
  EYELIDS_PTOSIS: 'EyeLids-Ptosis',
  EYELIDS_ENTROPION: 'EyeLids-Entropion',
  EYELIDS_EPIPHORA: 'EyeLids-Epiphora',
  EYELIDS_MRD1: 'EyeLids-MRD1',
  EYELIDS_PIGMENT: 'EyeLids-Pigment',
  EYELIDS_DESCRIPTION: 'EyeLids-Description',
  WORKLIST_REASONS: 'WorkListReasons',
  LILLY_LEE_FAVOURITE: 'FavoriteType',
  CHARGE_TYPE: 'ChargeTypes',
  DAY_OF_WEEK: 'DayOfWeek',
  PRODUCT_TYPE: 'Product Type',
  PAYMENT_WRITE_OFF_REASON: 'PaymentWriteOffReason',
  PAYMENT_TRANSACTION_TYPE: 'PaymentTransactionType',
  MANUFACTURER_LIST: 'ManufacturerList',
  CHARGE_CODE: 'ListofVCodes',
};

const listId = {
  PATIENTS: 'PATIENTS',
  CLAIMS: 'CLAIMS',
  COLLECTION_CLAIMS: 'COLLECTION_CLAIMS',
  CLAIMS_ERRORS: 'CLAIMS_ERRORS',
  PATIENT_CLAIM_TABLE: 'PATIENT_CLAIM_TABLE',
  REMITTANCE: 'REMITTANCE',
  COLLECTIONS: 'COLLECTIONS',
  CLAIMS_COLLECTIONS: 'CLAIMS_COLLECTIONS',
  CLAIMS_REMITTANCE: 'CLAIMS_REMITTANCE',
  LOCATIONS: 'LOCATIONS',
  PROVIDERS: 'PROVIDERS',
  SETTINGS_PROVIDERS: 'SETTINGS_PROVIDERS',
  PAYER_CPT_ICD: 'PAYER_CPT_ICD',
  SETTINGS_USERS: 'SETTINGS_USERS',
  USER_RIGHTS_MASTER: 'USER_RIGHTS_MASTER',
  USER_RIGHTS: 'USER_RIGHTS',
  UPDATE_USER_RIGHTS: 'UPDATE_USER_RIGHTS',
  CONTRACT: 'CONTRACT',
  PROVIDER_CREDENTIALS: 'CREDENTIALS',
  AUTHORIZATIONS: 'authorizations',
  CONTRACT_TERMS: 'CONTRACT_TERMS',
  DOCUMENT_CATEGORY: 'DOCUMENT_CATEGORY',
  DOCUMENT_CATEGORY_COUNTS: 'DOCUMENT_CATEGORY_COUNTS',
  PATIENT_AUTHORIZATION_DOCUMENTS: 'PATIENT_DOCUMENTS',
  PATIENT_AUTHORIZATION_DOCUMENT: 'PATIENT_DOCUMENT',
  ADD_PAYER: 'ADD_PAYER',
  EDIT_PAYER: 'EDIT_PAYER',
  BA_PATIENT: 'BA_PATIENTS',
  SCHEDULAR_ADD_APPOINTMENT_PATIENT: 'SCHEDULAR_ADD_APPOINTMENT_PATIENT',
  SCHEDULAR_PATIENTS_LIST: 'SCHEDULAR_PATIENTS_LIST',
  ADD_APPOINTMENT: 'ADD_APPOINTMENT',
  PATIENT_INSURANCE_PROFILE_POLICY: 'PATIENT_INSURANCE_PROFILE_POLICY',
  RECENTLY_CREATED_PATIENTS: 'RECENTLY_CREATED_PATIENTS',
  BILLING: 'BILLING',
  IMAGING_HISTORY: 'IMAGING_HISTORY',
  NEW_PATIENT_PROCEDURE: 'NEW_PATIENT_PROCEDURE',
  ADDRESS_BOOK_TABLE: 'ADDRESS_BOOK_TABLE',
  PATIENT_CLAIM_LIST: 'PATIENT_CLAIM_LIST',
  CHANGE_PATIENT_RESPONSIBILITY: 'CHANGE_PATIENT_RESPONSIBILITY',
  CHANGE_INSURANCE_RESPONSIBILITY: 'CHANGE_INSURANCE_RESPONSIBILITY',
  GET_CLAIMS_DETAILS: 'GET_CLAIMS_DETAILS',
  INSURANCE_PROFILE_DETAILS_SELECT: 'INSURANCE_PROFILE_DETAILS_SELECT',
  INSURANCE_DETAILS: 'INSURANCE_DETAILS',
  REMITTANCE_WORK_LIST_SUSPENSE: 'REMITTANCE_WORK_LIST_SUSPENSE',
  REMITTANCE_WORK_LIST_SUSPENSE_DETAIL: 'remittance-worklist-suspense-detail',
  REMITTANCE_WORK_LIST_REASON: 'REMITTANCE_WORK_LIST_REASON',
  GET_UNPOSTED_INVENTORY_LIST: 'un-posted-inventory-list',
  GET_PATIENT_ALLERGY_LIST: 'GET_PATIENT_ALLERGY_LIST',
};

const detailId = {
  PATIENT: 'patient-detail',
};

const contentType = {
  MULTIPART: 'multipart',
};

const labelPaths = {
  DASHBOARD: 'dashboard',
  ADD_PATIENT: 'patients/add',
  EDIT_PATIENT_DEMOGRAPHIC: 'patients/edit/demographic',
  PATIENT_LIST: 'patients/list',
  CLAIMS: 'claims',
  CLAIMS_LIST: 'claims/claims/list',
  REMITTANCE_LIST: 'claims/remittance/list',
  COLLECTIONS_LIST: 'claims/collections/list',
  COLLECTION_CLAIMS_LIST: 'claims/collections/claims/list',
  EDIT_PATIENT: 'patients/edit',
  ADD_PROVIDER: 'provider/add',
  PROVIDER_LIST: 'provider/list',
  EDIT_PROVIDER: 'provider/edit',
  EDIT_PROVIDER_ASSISTANTS: 'provider/edit/assistants',
  EDIT_PROVIDER_GENERAL: 'provider/edit/general',
  EDIT_PROVIDER_CREDENTIALS: 'provider/edit/credentials',
  EDIT_PROVIDER_BILLING: 'provider/edit/billing',
  EDIT_PROVIDER_PAYERHOLD: 'provider/edit/payerHold',
  EDIT_PROVIDER_EPRESCRIPTION: 'provider/edit/ePrescription',
  EDIT_PROVIDER_FEEPREFRENCES: 'provider/edit/feePrefrences',
  ADD_USER: 'user/add',
  USER_LIST: 'user/list',
  EDIT_USER: 'user/edit',
  EDIT_USER_GENERAL: 'user/edit/general',
  EDIT_USER_PROFILE: 'user/edit/profile',
  EDIT_USER_RIGHTS: 'user/edit/rights',
  PATIENT_FINANCIAL: 'patients/edit/financial',
  FINANCIAL_POPUP_PATIENT_PAYMENT: 'patients/edit/financial/popups/patientPayment',
  FINANCIAL_POPUP_PATIENT_SIMPLE_CHARGE: 'patients/edit/financial/popups/patientSimpleCharge',
  FINANCIAL_POPUP_PATIENT_ADJUSTMENT: 'patients/edit/financial/popups/patientAdjustment',
  FINANCIAL_POPUP_INSURANCE_PAYMENT: 'patients/edit/financial/popups/insurancePayment',
  FINANCIAL_POPUP_INSURANCE_REFUND: 'patients/edit/financial/popups/insuranceRefund',
  FINANCIAL_POPUP_INSURANCE_ADJUSTMENT: 'patients/edit/financial/popups/insuranceAdjustment',
  PATIENT_DASHBOARD: 'patients/edit/dashboard',
  PATIENT_DOCUMENTS: 'patients/edit/documents',
  PATIENT_ENCOUNTERS: 'patients/edit/encounters',
  ENCOUNTERS_BALANCE: 'patients/edit/encounters/tab/balance',
  ENCOUNTERS_DIAGNOSIS: 'patients/edit/encounters/tab/diagnosis',
  ENCOUNTERS_DEMOGRAPHIC: 'patients/edit/encounters/tab/demographic',
  ENCOUNTERS_CASES: 'patients/edit/encounters/tab/cases',
  ENCOUNTER_HISTORY: 'patients/edit/encounters/tab/history',
  ENCOUNTER_AUTHORIZATION: 'patients/edit/encounters/tab/authorization',
  CLAIMS_DETAILS: 'patients/edit/bills/claims/details',
  STATEMENT_DETAILS: 'patients/edit/bills/statements/details',
  PATIENT_BILLS: 'patients/edit/bills',
  PATIENT_LISTS: 'patients/edit/lists',
  CONTRACT_LIST: 'contracts/list',
  NEW_CONTRACT: 'contracts/new',
  EDIT_CONTRACT: 'contracts/edit',
  EDIT_CONTRACT_TERMS: 'contracts/edit/terms',
  EDIT_CONTRACT_TERMS_GENERAL: 'contracts/edit/terms/general',
  EDIT_CONTRACT_TERMS_EXCEPTIONS: 'contracts/edit/terms/exceptions',
  EDIT_CONTRACT_TERMS_MODIFIERS: 'contracts/edit/terms/modifiers',
  PAYER_MODAL: 'modals/Payer',
  PERSON_MODAL: 'modals/Person',
  TASKS_MODAL: 'modals/Tasks',
  ERROR_POPUP: 'claims/errorPopup',
  BATCH_POPUP: 'claims/batchPopup',
  LOCATION_LIST: 'locations/list',
  ADD_LOCATION: 'locations/add',
  EDIT_LOCATION: 'locations/edit',
  EDIT_LOCATION_GENERAL: 'locations/edit/general',
  EDIT_LOCATION_EXAMROOM_LIST: 'locations/edit/examRoom/list',
  EDIT_LOCATION_ADD_EXAMROOM: 'locations/edit/examRoom/add',
  EDIT_LOCATION_EDIT_EXAMROOM: 'locations/edit/examRoom/edit',
  VIEW_RESPONSIBLE_PARTY: 'insurance/responsibleParty/view',
  EDIT_RESPONSIBLE_PARTY: 'insurance/responsibleParty/edit',
  PROFILE_NAME: 'insurance/responsibleParty/rename',
  EDIT_INSURANCE_POLICY: 'insurance/policy/edit',
  ADD_INSURANCE_POLICY: 'insurance/policy/add',
  ADD_INSURANCE_PROFILE: 'insurance/profile/add',
  VIEW_INSURANCE_PROFILE: 'insurance/profile/view',
  SCHEDULAR: 'schedular',
  ADD_APPOINTMENT_SCHEDULAR: 'schedular/dialogs/addAppointment',
  APPOINTMENT_COMPONENT_SCHEDULAR: 'schedular/appointmentComponents',
  DOCTOR: 'doctor',
  DOCTOR_VISION_MODAL: 'doctor/modals/vision',
  DOCTOR_PRESSURE_MODAL: 'doctor/modals/pressure',
  DOCTOR_SUPERBILL_MODAL: 'doctor/modals/superbill',
  TECHNICIAN: 'technician',
  // TECHNICIAN_HPI: 'technician/tabs/hpi',
  TECHNICIAN_COOLSCULPT: 'technician/coolsculpt',
  TECHNICIAN_ULTHERAPY: 'technician/ultherapy',
  TECHNICIAN_TREATMENT: 'technician/treatment',
  DOCTOR_SIDE_PANEL: 'doctor/panel',
  DOCTOR_PATIENT_DETAIL: 'doctor/patientDetail',
  MEDICATIONS_LIST: 'doctor/encounterDetail/medications/list',
  TECHNICIAN_ENCOUNTER_TABS: 'technician/encounterDetail/encounterTabs',
  ADD_MEDICATION: 'doctor/encounterDetail/medications/addMedication',
  ADD_PROCEDURE: 'doctor/encounterDetail/procedure/addProcedure',
  IMAGING_HISTORY_LIST: 'doctor/encounterDetail/imagingHistory/list',
  VIEW_IMAGE: 'doctor/encounterDetail/imagingHistory/viewImage',
  ADD_IMAGE: 'doctor/encounterDetail/imagingHistory/addImage',
  VIEW_SENSORIMOTOR: 'doctor/encounterDetail/sensorimotor',
  DRAW: 'doctor/encounterDetail/draw',
  ADD_PAYER: 'payer/add',
  VIEW_PAYER: 'payer/list',
  ADD_ICD_CPT_MAPPING: 'payer/addMapping',
  ADD_AUTHORIZATION: 'patients/edit/lists/authorization/add',
  EDIT_AUTHORIZATION: 'patients/edit/lists/authorization/edit',
  // TECHNICIAN_ROS: 'technician/tabs/ros',
  SCHEDULAR_SETTING: 'schedularSetting',
  REFRACT: 'doctor/encounterDetail/refract',
  REFRACT_MODAL: 'doctor/encounterDetail/refract/refractModal',
  SCHEDULAR_SETTINGS: 'schedularSettings',
  BLOCK_OUT_LIST: 'schedularSettings/blockouts/list',
  ADD_BLOCKOUT: 'schedularSettings/blockouts/add',
  EDIT_BLOCKOUT: 'schedularSettings/blockouts/edit',
  SCHEDULAR_SETTING_VISIT_TYPE: 'schedularSettings/visitTypes',
  SCHEDULAR_SETTING_TEST_TYPE: 'schedularSettings/testTypes',
  SCHEDULAR_SETTING_BLOCKOUT_TEMPLATES_LIST: 'schedularSettings/BlockOut&Templates/list',
  CANCELLATION_REASONS: 'schedularSettings/cancellations',
  SCHEDULAR_SETTINGS_BASICS: 'schedularSettings/basics',
  SCHEDULAR_SETTINGS_RESOURCES: 'schedularSettings/resources',
  TEMPLATE_LIST: 'schedularSettings/template/list',
  ADD_TEMPLATE: 'schedularSettings/template/add',
  EDIT_TEMPLATE: 'schedularSettings/template/edit',
  DOCTOR_ENCOUNTER_DETAIL_OTHER: 'doctor/encounterDetail/other',
  INSURANCE_SIDEBAR: 'insurance/sideBar',
  DOCTOR_ENCOUNTER_DETAIL_OCULAR_LIST: 'doctor/encounterDetail/ocularProblems/list',
  DOCTOR_ENCOUNTER_DETAIL_OCULAR_ADD: 'doctor/encounterDetail/ocularProblems/add',
  DOCTOR_ENCOUNTER_DETAIL_OCULAR_EDIT: 'doctor/encounterDetail/ocularProblems/edit',
  BOOK_APPOINTMENT: 'bookAppointment',
  DASHBOARD_CHECK_OUT: 'dashboard/checkOut',
  DASHBOARD_CHECK_IN: 'dashboard/checkIn',
  BILLING_LIST: 'billing/list',
  BILLING_ADD: 'billing/add',
  CREATE_NEW_CLAIM_POPUP: 'claims/createnewClaim',
  MARK_FOLLOW_UP_POPUP: 'claims/markFollowUpClaim',
  PATIENT_PAYMENT_POPUP: 'claims/patientPaymentClaim',
  CHANGE_RESPONSIBILITY_POPUP: 'claims/changeResponsibility',
  BILL_PATIENT_POPUP: 'claims/billPatient',
  TECHNICIAN_EXAM: 'technician/exam',
  DOCTOR_VISION: 'doctor/encounterDetail/vision',
  DOCTOR_PRESSURE: 'doctor/encounterDetail/pressure',
  DOCTOR_CHIEF_COMPLAINT: 'doctor/encounterDetail/chiefComplaint',
  OUTSIDE_TESTING: 'doctor/encounterDetail/outsideTesting',
  DOCTOR_FOLLOW_UP: 'doctor/followUp',
  DOCTOR_SIGN_AND_BILL: 'doctor/followUp/signAndBill',
  DOCTOR_ORDER_SET: 'doctor/orderSet',
  DASHBOARD_ACTIVITY_LOG: 'dashboard/activityLog',
  CARE_TEAM: 'doctor/encounterDetail/addCareTeam',
  CLAIM_OVERVIEW: 'claims/collections/claims/overview',
  IMPORT_DATA_FROM_FILE: 'data/tabs/importDataFromFile',
  IMPORT_FRAMES_DATA: 'data/tabs/importFramesData',
  LIST_PRODUCTS: 'data/tabs/listProducts',
  INVENTORY_DISPENSE: 'inventory/dispense',
  PHYSICAL_INVENTORY_REPORTS: 'inventory/reports/physicalInventory',
  DAILY_TRANSACTIONS_REPORTS: 'inventory/reports/dailyTransactions',
  ITEMS_SOLD_REPORTS: 'inventory/reports/itemsSold',
  DOLLAR_VALUE_SOLD_REPORTS: 'inventory/reports/dollarValueSold',
  SALES_TAX_REPORTS: 'inventory/reports/salesTax',
  RECALLED_ITEMS_REPORTS: 'inventory/reports/recalledItems',
  DISCOUNT_PROMOTION_REPORTS: 'inventory/reports/discountPromotion',
  INVENTORY_ADJUSTMENT_REPORTS: 'inventory/reports/inventoryAdjustment',
  INVENTORY_TRANSFERS_REPORTS: 'inventory/reports/inventoryTransfers',
  INVENTORY_CREATE_INVOICE: 'inventory/optical/createInvoice',
  INVENTORY_INVOICE_ADD_ITEMS: 'inventory/optical/createInvoice/addItem',
  INVENTORY_CREATE_KIT: 'inventory/optical/createKit',
  INVENTORY_UN_PORTED_BATCHES: 'inventory/optical/UnPostedBatches',
  INVENTORY_ADJUST_ON_HAND: 'inventory/optical/AdjustOnHandQty',
  INVENTORY_TRANSFER: 'inventory/optical/inventoryTransfer',
  INVENTORY_REPRINT_LABELS: 'inventory/optical/reprintLabels',
  INVENTORY_SEARCH_BARCODE: 'inventory/optical/searchBarcode',
  SETTINGS_BUSINESS_ENTITY_ADD: 'settings/businessEntity/add',
  SETTINGS_BUSINESS_ENTITY_EDIT: 'settings/businessEntity/edit',
  SETTINGS_BUSINESS_ENTITY_LIST: 'settings/businessEntity/list',
  SETTINGS_BUSINESS_ENTITY_EDIT_GENERAL: 'settings/businessEntity/edit/tabs/general2',
  SETTINGS_BUSINESS_ENTITY_LIST_CREDENTIALS: 'settings/businessEntity/edit/tabs/credentials',
  SETTINGS_BUSINESS_ENTITY_LIST_STATEMENTS: 'settings/businessEntity/edit/tabs/statements',
  SETTINGS_BUSINESS_ENTITY_LIST_PATIENT: 'settings/businessEntity/edit/tabs/patients',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_BASIC: 'settings/businessEntity/edit/tabs/patients/basics',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_CHARTS: 'settings/businessEntity/edit/tabs/patients/charts',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_REFERRAL_SOURCES: 'settings/businessEntity/edit/tabs/patients/referralSource/list',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_REFERRAL_SOURCES_ADD: 'settings/businessEntity/edit/tabs/patients/referralSource/add',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_REFERRAL_SOURCES_EDIT: 'settings/businessEntity/edit/tabs/patients/referralSource/edit',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_ADJUSTMENT_REASON: 'settings/businessEntity/edit/tabs/patients/adjustmentReason/list',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_ADJUSTMENT_REASON_ADD: 'settings/businessEntity/edit/tabs/patients/adjustmentReason/add',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_ADJUSTMENT_REASON_EDIT: 'settings/businessEntity/edit/tabs/patients/adjustmentReason/edit',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_SIMPLE_CHARGE: 'settings/businessEntity/edit/tabs/patients/simpleCharge/list',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_SIMPLE_CHARGE_ADD: 'settings/businessEntity/edit/tabs/patients/simpleCharge/add',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_SIMPLE_CHARGE_EDIT: 'settings/businessEntity/edit/tabs/patients/simpleCharge/edit',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_CARE_TEAM: 'settings/businessEntity/edit/tabs/patients/careTeam/list',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_CARE_TEAM_ADD: 'settings/businessEntity/edit/tabs/patients/careTeam/add',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_CARE_TEAM_EDIT: 'settings/businessEntity/edit/tabs/patients/careTeam/edit',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_BASICS: 'settings/businessEntity/edit/tabs/Billing/Basics',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_PAYER_AGING: 'settings/businessEntity/edit/tabs/Billing/payerAgingOverrides/list',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_PAYER_AGING_ADD: 'settings/businessEntity/edit/tabs/Billing/payerAgingOverrides/add',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_PAYER_AGING_EDIT: 'settings/businessEntity/edit/tabs/Billing/payerAgingOverrides/edit',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_TABS: 'settings/businessEntity/edit/tabs/Billing',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_CASES: 'settings/businessEntity/edit/tabs/Billing/cases/list',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_CASES_ADD: 'settings/businessEntity/edit/tabs/Billing/cases/add',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_CASES_EDIT: 'settings/businessEntity/edit/tabs/Billing/cases/edit',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_CLAIMS: 'settings/businessEntity/edit/tabs/claims',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_REMITTANCE: 'settings/businessEntity/edit/tabs/remittance',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_PAYER_AGING_TABS: 'settings/businessEntity/edit/tabs/Billing/payerAgingOverrides',
  SETTINGS_BUSINESS_ENTITY_EDIT_DOCUMENTS_LIST: 'settings/businessEntity/edit/tabs/documents/list',
  SETTINGS_BUSINESS_ENTITY_EDIT_DOCUMENTS_ADD_CATEGORY: 'settings/businessEntity/edit/tabs/documents/add',
  SETTINGS_BUSINESS_ENTITY_EDIT_DOCUMENTS_EDIT_CATEGORY: 'settings/businessEntity/edit/tabs/documents/edit/index.json',
  SETTINGS_BUSINESS_ENTITY_EDIT_DOCUMENTS: 'settings/businessEntity/edit/tabs/documents/index.json',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_NDC: 'settings/businessEntity/edit/tabs/Billing/ndc',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_INSTITUTIONAL: 'settings/businessEntity/edit/tabs/Billing/institutional',
  BILLING_TASKS_ALL: 'billing/tasks/tabs/All',
  REMITTANCE_NEW_INSURANCE: 'claims/remittance/insurancePayment',
  REMITTANCE_PATIENT_PAYMENT: 'claims/remittance/patientPayment',
  TASK_LIST: 'billing/tasks/list',
  REMITTANCE_BATCH_DETAILS: 'claims/remittance/batch',
  REMITTANCE_WORK_LIST: 'claims/remittance/worklist',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_SCHEDULES_LIST: 'settings/businessEntity/edit/tabs/schedules/list',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_SCHEDULES_ADD: 'settings/businessEntity/edit/tabs/schedules/add',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_SCHEDULES_EDIT: 'settings/businessEntity/edit/tabs/schedules/edit',
};

const enumDataIds = {
  INVITATION_ID: 350,
  AUTHORIZATION: 464,
  CAPITATION: 430,
  FEE_FOR_SERVICE: 431,
  FEE_FOR_SERVICE_VALUE_IF_NO_PROCEDURE_ID: 463,
  ALL_PLACES_OF_SERVICE: 451,
  SPECIFIC_PLACES_OF_SERVICE: 452,
  SPECIFIC_LOCATION: 453,
  CAPITATION_PAYMENT_ARRANGEMENT: 454,
  TERMS_EXCEPTION_TYPE_ID: 517,
  TERMS_EXCEPTION_VALUE_TYPE_ID_AMOUNT: 518,
  TECHNICIAN_VISION: 520,
  TECHNICIAN_PRESSURE: 521,
  PROVIDER_FEE_PREFERENCES_EXCEPTION_VALUE_TYPE: 544,
  PROVIDER_FEE_PREFERENCES_EXCEPTION_FACILITY: 45,
  PROVIDER_FEE_PREFERENCES_EXCEPTION_MODIFIER: 540,
  ALL_POLICY_TYPE_ID: 527,
  ALL_PLAN_TYPE_ID: 529,
  FOLLOW_UP_VISIT_TYPE_FOLLOW_SHORT: 118,
  REFERRING_PHYSICIAN_TYPE_PATIENT_CARE: 669,
  PRIMARY_CARE_PHYSICIAN_TYPE_PATIENT_CARE: 576,
  PREFERRED_LANGUAGE_ENGLISH: 11,
  PREFERRED_CONTACT_PHONE: 12,
  QUEUE_CLAIMS: 786,
  QUEUE_COLLECTION: 788,
  STATE_READY: 793,
  STATE_ERROR: 794,
  STATE_CLEARING_HOUSE: 795,
  STATE_NEW: 789,
  STATE_IN_PROGRESS: 790,
  STATE_NEED_ACTION: 791,
  STATE_SMALL_BALANCE: 799,
  STATE_COMPLETED: 792,
  STATE_UNBATCH: 797,
  STATE_OUTBOX: 798,
  STAGE_READY: 861,
  STAGE_CLEARING_HOUSE: 865,
  STAGE_ERROR: 879,
  STAGE_COMPLETED: 860,
  STAGE_NEW: 862,
  STAGE_IN_PROGRESS: 863,
  STAGE_NEED_ACTION: 864,
  STAGE_SMALL_BALANCE: 869,
  STAGE_NO_RESPONSE: 870,
  STAGE_DENIAL: 871,
  STAGE_MANUAL: 872,
  STAGE_OUTBOX: 868,
  STAGE_UNBATCH: 867,
  STATEMENT_METHOD_ELECTRONIC: 374,
  ERROR_TYPE_ALL: 909,
  ERROR_TYPE_NONE: 910,
  COLLECTION_GROUPING: 109,
  QUEUE_REMITTANCE: 787,
  RETINA_SPECIALIST_ID: 589,
  PAPER_CLAIM: 885,
  WORKERS_COMP: 918,
  ADDITIONAL_FILTER_PAYER: 922,
  ADDITIONAL_FILTER_LOCATION: 921,
  ADDITIONAL_FILTER_AGING: 920,
  STAGE_SUBMITTED: 866,
  STATE_SUBMITTED: 796,
  REMITTANCE_INSURANCE_PAYMENT: 934,
  URGENT: 952,
  STANDARD: 981,
  CUTERA_AREA_OTHER: 995,
  CUTERA_AREA_IPL_KJ_OTHER: 1676,
  MICRO_NEEDLING_ADDITIVE_AGENT_OTHER: 992,
  ADDRESS_BOOK_PROVIDER_TYPE: 94,
  CASE_TYPE: 35,
  ADJUSTMENT_REASON: 149,
  REFERRAL_SOURCE: 27,
  SIMPLE_CHARGES: 153,
  FAVOURITE_TYPE_HPI: 1097,
};

const enumMasterCode = {
  MedicareHMO: 'MedicareHMO',
  MedicaidHMO: 'MedicaidHMO',
  BlueCrossBlueShield: 'BlueCrossBlueShield',
  WorkersComp: 'WorkersComp',
  HMO: 'HMO',
  Capitation: 'Capitation',
  FAVOURITE_TYPE_HPI_MASTER_CODE: 'FavoriteType-HPI',
};

const I_FRAME_URL = 'I_FRAME';
const I_FRAME_URL_ALLERGY = 'I_FRAME_ALLERGY';

const urls = {
  MAP: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBaKpeUqZ8A3DYMR0Lt2kVupph0LHLbFCo&v=3.exp&libraries=geometry,drawing,places',
  SEARCH_MAP: 'https://www.google.com/maps/search/?api=1&query=',
};

const keys = {
  CAPTCHA: '6LdmXKwZAAAAABDBhwL1jU5Ni5eK5rlyo40uablE',
};

const dateFormat = 'MM-DD-YYYY';
const timeFormat = 'HH:mm:ss';
const dateFormatWithHour = 'MM-DD-YYYY HH:mm';
const dateFormatWithSlash = 'MM/DD/YYYY';
const dateFormatStartsWithYear = 'YYYY-MM-DD';
const dateFormatStartsWithYearHour = 'YYYY-MM-DD HH:mm';
const dateFormatStartsWithYearSeconds = 'YYYY-MM-DD HH:mm:ss';
const schedulerDataDateFormat = 'MM/DD/YYYY h:mm:ss A';

const schedularLocationColorRules = [{
  minPercent: -Infinity,
  maxPercent: 30,
  color: '#66BB6A',
}, {
  minPercent: 30,
  maxPercent: 60,
  color: '#ffeb3b',
}, {
  minPercent: 60,
  maxPercent: 80,
  color: '#ec407a',
},
{
  minPercent: 80,
  maxPercent: 100,
  color: '#FFA726',
}, {
  minPercent: 100,
  maxPercent: Infinity,
  color: '#f44336',
}];

const patientCardColorRules = [{
  minPercent: -Infinity,
  maxPercent: 10,
  class: 'green-pannel',
}, {
  minPercent: 10,
  maxPercent: 20,
  class: 'yellow-pannel',
}, {
  minPercent: 20,
  maxPercent: Infinity,
  class: 'orange-pannel',
}];

const mimeType = {
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

const responseType = {
  ARRAY_BUFFER: 'arraybuffer',
  BLOB: 'blob',
};

const refractionDefaultManifestId = 596;
const refractionContactLensesManifestId = 598;

export {
  UiRoutes,
  formName,
  enums,
  formId,
  listId,
  contentType,
  listIds,
  labelPaths,
  detailId,
  enumDataIds,
  urls,
  dateFormat,
  timeFormat,
  dateFormatWithHour,
  dateFormatWithSlash,
  dateFormatStartsWithYear,
  dateFormatStartsWithYearHour,
  dateFormatStartsWithYearSeconds,
  schedulerDataDateFormat,
  keys,
  schedularLocationColorRules,
  I_FRAME_URL,
  mimeType,
  I_FRAME_URL_ALLERGY,
  responseType,
  patientCardColorRules,
  refractionDefaultManifestId,
  refractionContactLensesManifestId,
  enumMasterCode,
};
