import React, { useState, useCallback } from 'react';
import './medicinecard.scss';

const MedicationCard = (props) => {
  const { medicationCardValue } = props;
  const {
    name, dose, WhenToTake, image, activeClass, instruction,
  } = medicationCardValue;
  const [clickActive, setClickActive] = useState(activeClass);

  const ClickToActive = useCallback(() => {
    setClickActive(!clickActive);
  }, [clickActive]);
  return (
    <div className="medicine-card-section">
      <div
        onClick={ClickToActive}
        onKeyDown={ClickToActive}
        className={`medicine-card ${clickActive ? 'active' : ''}`}
        role="button"
        tabIndex={0}
      >
        <div className="medication-card-wrap">
          <div className="img-area">
            <img src={`${process.env.PUBLIC_URL}${image}`} width="60" alt="logo_img" />
          </div>
          <div className="user-details">
            <p className="name">{name}</p>
            <p className="details">
              <span className="dose">{dose}</span>
              <span className="WhenToTake">{WhenToTake}</span>
            </p>
          </div>
        </div>
        <div className="checkbox-wrap">
          <div className="active-check" />
        </div>
      </div>
      {instruction
        ? <p className="instructions">{instruction}</p> : ''}
    </div>
  );
};

export default MedicationCard;
