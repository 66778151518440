import React from 'react';
import './welcomecard.scss';
import Button from '../Button';

const WelcomeCard = (props) => {
  const { welcomeCardDetail } = props;
  const {
    image, name, location, notify, button,
  } = welcomeCardDetail;

  return (
    <div className="welcome-card">
      <div className="user-profile-wrap">
        <div className="img-area">
          <img src={`${process.env.PUBLIC_URL}${image}`} alt="logo_img" />
        </div>
        <div className="user-details">
          <p className="welcome-massage">
            <span className="bold">
              Welcome
              {' '}
              {name}
              ,
              {' '}
            </span>
            you have an appointment with Dr. Gretchen Bergson in 10 min.
            {' '}
          </p>
          <p className="location">{location}</p>
          <div className="notify-text-button">
            <p className="notify-text">{notify}</p>
            {button
              ? <Button className="primary">I am Here</Button>
              : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeCard;
