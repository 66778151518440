import React from 'react';
import './appointmentbooklist.scss';

const AppointmentBookList = (props) => {
  const { AppointmentBookData, handleOnClick } = props;
  const {
    image, name, occupasion, location, time,
  } = AppointmentBookData;

  return (
    <div
      className="appointment-book-list-wrap"
      onClick={handleOnClick}
      onKeyDown={handleOnClick}
      role="button"
      tabIndex={0}
    >
      <div className="user-profile-wrap">
        <div className="img-area">
          <img src={`${process.env.PUBLIC_URL}${image}`} width="60" alt="logo_img" />
        </div>
        <div className="user-details">
          <p className="name">{name}</p>
          <p className="details">{occupasion}</p>
        </div>
      </div>
      <div className="location-details">
        <p className="location">{location}</p>
        <p className="time">{time}</p>
      </div>
      <div className="card-footer">
        <div className="checkbox-wrap">
          <div className="active-check"><span>Select Doctor</span></div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentBookList;
