/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Layout } from 'antd';
import { connect } from 'react-redux';

import '../css/mixin.scss';
import '../css/antd.css';
import './App.scss';
import '../css/style.scss';
import '../css/overrides.scss';
import { UiRoutes } from '../lib/constants';
// import SamplePage from '../screens/SamplePage';
import Sidebar from '../components/Sider';
import Content from './Content';
import Notification from './Notification';
import Header from './Header';

/* Core CSS required for Ionic components to work properly */
// import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
// import '@ionic/react/css/normalize.css';
// import '@ionic/react/css/structure.css';
// import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../theme/variables.css';

function App() {
  const [navOpen, setNavOpen] = useState(true);
  const [notificationOpen, setNotificationOpen] = useState(true);
  const navOpenAction = useCallback(() => {
    setNavOpen(!navOpen);
  }, [navOpen]);
  const notificationOpenAction = useCallback(() => {
    setNotificationOpen(!notificationOpen);
  }, [notificationOpen]);
  return (
    <BrowserRouter>
      <div className="app">
        <Switch>
          <Route exact path={UiRoutes.home} key="main page">
            <Layout>
              <Header
                navOpenAction={navOpenAction}
                notificationOpenAction={notificationOpenAction}
              />
              <Layout>
                <Sidebar navOpen={navOpen} />
                <Content />
                <Notification
                  notificationOpen={notificationOpen}
                  notificationOpenAction={notificationOpenAction}
                />
              </Layout>
            </Layout>
          </Route>
          {/* <Route exact path={UiRoutes.home2} component={SamplePage} key="main page" /> */}
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default connect()(App);
