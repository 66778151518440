
import React, { useState, useCallback } from 'react';
import Input from '../../components/Form/Input';
import Separator from '../../components/Separator';
import Carousel from '../../components/Carousel';
import Button from '../../components/Button';
import InsuranceCard2 from './components/InsuranceCard2';
import InsuranceCard1 from './components/InsuranceCard1';
import './insurance.scss';

const insuranceData = {
  payerName: 'ADOC',
  memberID: '234567656',
  groupID: '234567656',
  responsibleParty: 'Self',
};

const carouselList = [
  {
    comp: <InsuranceCard1 />,
  },
  {
    comp: <InsuranceCard2 />,
  },
];

function Insurance() {
  const [editForm, setEDitForm] = useState(false);

  const editFormAction = useCallback(() => {
    setEDitForm(!editForm);
  }, [editForm]);
  return (
    <div className="insurance-main-wrap" id="insurance">
      <div className="insurance-wrap-in">
        <div>
          <h2>Insurance Information</h2>
          <div className="">
            <Carousel carouselList={carouselList} />
          </div>
        </div>
        <div className="insurance-form-wrap">
          <form className={`${editForm ? 'editable' : 'non-editable'}`}>
            <div className="form-container">
              {editForm
                ? (
                  <div className="edit-mode-section">
                    <div className="row-wrap">
                      <div className="first-col">
                        <Input label="" className="bold-type" value={insuranceData.payerName} />
                        <Input label="" value={insuranceData.memberID} />
                      </div>
                      <div className="first-col">
                        <Input label="" value={insuranceData.groupID} />
                        <Input label="" value={insuranceData.responsibleParty} />
                      </div>
                    </div>
                    <Separator />
                    <div className="row-wrap">
                      <div className="first-col">
                        <Input label="" className="bold-type" value={insuranceData.payerName} />
                        <Input label="" value={insuranceData.memberID} />
                      </div>
                      <div className="first-col">
                        <Input label="" value={insuranceData.groupID} />
                        <Input label="" value={insuranceData.responsibleParty} />
                      </div>
                    </div>
                    <Separator />
                    <div className="row-wrap">
                      <div className="first-col">
                        <Input label="" className="bold-type" value={insuranceData.payerName} />
                        <Input label="" value={insuranceData.memberID} />
                      </div>
                      <div className="first-col">
                        <Input label="" value={insuranceData.groupID} />
                        <Input label="" value={insuranceData.responsibleParty} />
                      </div>
                    </div>
                    <Separator />
                    <div className="row-wrap">
                      <div className="first-col">
                        <Input label="" className="bold-type" value={insuranceData.payerName} />
                        <Input label="" value={insuranceData.memberID} />
                      </div>
                      <div className="first-col">
                        <Input label="" value={insuranceData.groupID} />
                        <Input label="" value={insuranceData.responsibleParty} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="view-mode-section">
                    <div className="row-wrap">
                      <div className="first-col">
                        <Input label="Payer Name" className="bold-type" value={insuranceData.payerName} />
                        <Input label="Member Id" value={insuranceData.memberID} />
                        <Input label="Group Id" value={insuranceData.groupID} />
                        <Input label="Responsible Party" value={insuranceData.responsibleParty} />
                      </div>
                      <div className="first-col mobile-none">
                        <Input label="Payer Name" className="bold-type" value={insuranceData.payerName} />
                        <Input label="Member Id" value={insuranceData.memberID} />
                        <Input label="Group Id" value={insuranceData.groupID} />
                        <Input label="Responsible Party" value={insuranceData.responsibleParty} />
                      </div>
                    </div>
                    <Separator className="dotted-line mobile-none" />
                    <div className="row-wrap mobile-none">
                      <div className="first-col">
                        <Input label="Payer Name" className="bold-type" value={insuranceData.payerName} />
                        <Input label="Member Id" value={insuranceData.memberID} />
                        <Input label="Group Id" value={insuranceData.groupID} />
                        <Input label="Responsible Party" value={insuranceData.responsibleParty} />
                      </div>
                      <div className="first-col">
                        <Input label="Payer Name" className="bold-type" value={insuranceData.payerName} />
                        <Input label="Member Id" value={insuranceData.memberID} />
                        <Input label="Group Id" value={insuranceData.groupID} />
                        <Input label="Responsible Party" value={insuranceData.responsibleParty} />
                      </div>
                    </div>
                    <div className="mobile-only">
                      <div className="text-center">
                        <Button className="appointment-btn warning">Remove Card</Button>
                      </div>
                    </div>
                  </div>
                )}
              <div className="insurance-action-btn">
                <Button className="link edit-icon sprite-img-before" onClick={editFormAction} />
                {!editForm && <Button className="link user-icon sprite-img-before" /> }
                {editForm && <Button className="link save-icon sprite-img-before" /> }
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Insurance;
