import React from 'react';
import './bookappointmentcard.scss';
import Button from '../Button';

const BookAppointmentCard = (props) => {
  const { appointmentBookDetail } = props;
  const {
    name, occupation, image, rescheduleBtn, cancelBtn, viewCartBtn, checkInBtn,
  } = appointmentBookDetail;

  return (
    <div className="book-appointment-wrap">
      <div className="appointment-profile-wrap">
        <div className="appointment-profile-wrap-in">
          <div className="img-area">
            <img src={`${process.env.PUBLIC_URL}${image}`} width="70" alt="logo_img" />
          </div>
          <div className="appointment-user-details">
            <p className="name">{name}</p>
            <p className="details">{occupation}</p>
          </div>
        </div>
        <div className="book-appointment">
          {rescheduleBtn && cancelBtn
            ? (
              <>
                <Button className="appointment-btn">{rescheduleBtn}</Button>
                <Button className="appointment-btn warning">{cancelBtn}</Button>
              </>
            ) : ''}
          {viewCartBtn
            ? (
              <div className="desktop-only">
                <Button className="outline">{viewCartBtn}</Button>
              </div>
            ) : ''}
        </div>
      </div>
      <div className="appointment-bottom">
        <div className="appointment-bottom-details">
          <div className="date">21 / 12/ 2021</div>
          <div className="time">18:00 PM</div>
          <div className="location">554 South Pasadena</div>
          <div className="self">Self</div>
        </div>
        <div className="appointment-bottom-button">
          {checkInBtn
            ? <Button className="primary arrow"><span className="sprite-img-before">Check In</span></Button>
            : ''}
          {viewCartBtn
            ? (
              <div className="mobile-only">
                <Button className="outline">{viewCartBtn}</Button>
              </div>
            ) : ''}
        </div>
      </div>
    </div>
  );
};

export default BookAppointmentCard;
