import React from 'react';
import { Select } from 'antd';
import './filterdropdown.scss';

const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}

const FilterDropdown = (props) => {
  const { filterValue } = props;

  return (
    <div className="filter-wrap">
      <Select defaultValue={filterValue[0]} style={{ width: 200 }} onChange={handleChange}>
        {filterValue.map((item, index) => {
          if (index === 0) return null;
          return (
            <Option key={item} value={item}>{item}</Option>
          );
        })}
      </Select>
    </div>
  );
};

export default FilterDropdown;
