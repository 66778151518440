import {
  takeLatest, put, call, all, takeEvery,
} from 'redux-saga/effects';
import get from 'lodash/get';

import {
  setSelectedDoctorsSchedulerData,
  RESCHEDULE_APPOINTMENT, resetAppointment,
  setSelectedDoctorsFreeSlots,
  getSelectedDoctorsFreeSlots,
  setLoading,
  setError,
  GET_SELECTED_DOCTORS_DATA,
  GET_SELECTED_DOCTORS_FREE_SLOTS,
  REMOVE_APPOINTMENT,
  updateAppointmentId,
} from '../actions/doctors';

import api from '../../api';
import { apiUrls } from '../../api/constants';
import decode from '../../lib/decode';

const getSelectedProvider = () => {
  let queryString = window.location.search;
  queryString = queryString.split('?');

  if (queryString[1]) {
    const queryStringObj = decode(queryString[1]);
    if (get(queryStringObj, 'doctor')) {
      return get(queryStringObj, 'doctor');
    }
  }
  return null;
};

export const getSchedulerData = function* (params) {
  const { payload } = params;
  const {
    providerId, scheduleEndDate, scheduleStartDate, locationId,
  } = payload;
  try {
    yield put(setLoading(true));

    const response = yield call(api.get, {
      token: localStorage.getItem('token'),
      url: apiUrls.GET_SCHEDULER_DATA,
      params: {
        providerId, scheduleEndDate, scheduleStartDate, locationId,
      },
    });
    yield put(setSelectedDoctorsSchedulerData(response));
    yield put(getSelectedDoctorsFreeSlots(payload));
  } catch (e) {
    yield put(setError(e));
  } finally {
    yield put(setLoading(false));
  }
};

export const rescheduleAppointment = function* ({ payload }) {
  const requestObj = {
    rescheduleAppointmentId: payload.currentAppointment.id,
    patientId: get(payload, 'currentAppointment.patient.id'),
    providerId: get(payload, 'currentAppointment.doctor.id'),
    locationId: get(payload, 'currentAppointment.location.id'),
    appointmentStartTime: get(payload, 'currentAppointment.startTime'),
    visitTypeId: get(payload, 'currentAppointment.visitType.visitTypeId'),
    insuranceId: get(payload, 'currentAppointment.insuranceId'),
    insurancedetailId: get(payload, 'currentAppointment.insurancedetailId'),
    totalDuration: get(payload, 'currentAppointment.totalDuration'),
    offset: payload.offset,
  };
  const { params } = payload;
  try {
    const response = yield call(api.put, {
      token: localStorage.getItem('token'),
      url: apiUrls.RESCHEDULE_APPOINTMENT,
      data: requestObj,
    });
    let { providerId } = requestObj;
    const selectedProvider = getSelectedProvider();
    if (selectedProvider) {
      providerId = selectedProvider;
    }
    yield put(getSelectedDoctorsFreeSlots({ ...params, providerId }));
    yield put(updateAppointmentId({ ...payload, newAppointmentId: get(response, 'appointmentId') }));
  } catch (e) {
    yield put(setError(e));
    yield put(resetAppointment({
      currentAppointment: payload.prevAppointment,
      prevAppointment: payload.currentAppointment,
    }));
  } finally {
    // yield put(setLoading(false));
  }
};

export const removeAppointment = function* ({ payload }) {
  try {
    let { data: { providerId } } = payload;
    const { params } = payload;
    const selectedProvider = getSelectedProvider();
    if (selectedProvider) {
      providerId = selectedProvider;
    }
    yield put(getSelectedDoctorsFreeSlots({ ...params, providerId }));
  } catch (e) {
    yield put(setError(e));
  }
};

export const getProvidersFreeSlots = function* (params) {
  const { payload } = params;
  try {
    // yield put(setLoading(true));

    const response = yield call(api.get, {
      token: localStorage.getItem('token'),
      url: apiUrls.GET_SCHEDULER_FREE_SLOTS,
      params: payload,
    });
    yield put(setSelectedDoctorsFreeSlots(response));
  } catch (e) {
    yield put(setError(e));
  } finally {
    // yield put(setLoading(false));
  }
};

export default function* root() {
  yield all([
    takeLatest(GET_SELECTED_DOCTORS_DATA, getSchedulerData),
    takeEvery(RESCHEDULE_APPOINTMENT, rescheduleAppointment),
    takeLatest(GET_SELECTED_DOCTORS_FREE_SLOTS, getProvidersFreeSlots),
    takeEvery(REMOVE_APPOINTMENT, removeAppointment),
  ]);
}
