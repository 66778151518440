
import React, { useState, useCallback } from 'react';
import BookAppointmentCard from '../../components/BookAppointmentCard';
import Button from '../../components/Button';
import Separator from '../../components/Separator';
import './appointment.scss';
import BookAppointment from './BookAppointment';

const appointmentBookDetail = {
  name: 'Dr. Gretchen Bergson',
  occupation: 'Ophthalmologist',
  date: '21 / 12/ 2021',
  time: '18:00 PM',
  image: '/images/user-img-1.png',
  rescheduleBtn: 'Reschedule',
  cancelBtn: 'Cancel',
  checkInBtn: 'Check In',
};
const appointmentBookDetail2 = {
  name: 'Dr. Carter Herwitz',
  occupation: 'Ophthalmologist',
  date: '21 / 12/ 2021',
  time: '18:00 PM',
  image: '/images/user-img-1.png',
  viewCartBtn: 'View Chart Notes',
};

function Appointment() {
  const [showAppointments, setShowAppointment] = useState(true);
  const showBookAppointments = useCallback(() => {
    setShowAppointment(!showAppointments);
  }, [showAppointments]);
  return (
    <div className="appointment-main-wrap" id="appointment">
      {showAppointments ? (
        <>
          <div className="appointment-title-head">
            <p className="title">Upcoming Appointments</p>
            <Button className="outline" onClick={showBookAppointments}>Book Appointment</Button>
          </div>
          <BookAppointmentCard appointmentBookDetail={appointmentBookDetail} />
          <Separator className="dotted" />
          <div className="appointment-title-head">
            <p className="title">Past Appointments</p>
          </div>
          <BookAppointmentCard appointmentBookDetail={appointmentBookDetail2} />
        </>
      )
        : <BookAppointment />}
    </div>
  );
}

export default Appointment;
