
import React from 'react';
import './concent.scss';

function Concent() {
  return (
    <div className="concent-main-wrap" id="concent">
      <div className="unavailable-wrap">
        <img src={`${process.env.PUBLIC_URL}/images/unavailable.png`} width="155" alt="logo_img" />
        <p className="unavailable-text">You do not have any more forms to be filled!</p>
      </div>
    </div>
  );
}

export default Concent;
