import React from 'react';
import './profile.scss';

const Profile = (props) => {
  const { profileDetails } = props;
  const { name, image } = profileDetails;
  return (
    <div className="profile-wrap">
      <div className="img-wrap">
        <img src={`${process.env.PUBLIC_URL}${image}`} width="80px" alt="" />
      </div>
      <div className="profile-text">
        <p>Hello,</p>
        <p>{name}</p>
      </div>
    </div>
  );
};

export default Profile;
