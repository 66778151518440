
import React, { useState } from 'react';
import './demographic.scss';
import UserImage from '../../components/UserImage';
import Input from '../../components/Form/Input';
import Separator from '../../components/Separator';
import Button from '../../components/Button';
import StepAction from '../../components/Steps';
import GeneralInfo from './components/GeneralInfo';
import ContactInfo from './components/ContactInfo';
import IntakeInfo from './components/IntakeInfo';

const UserImageData = {
  image: '/images/User-pic.png',
};

const DemographicData = {
  fname: 'Ashlynn',
  mname: 'Westervelt',
  lname: '(F)',
  dob: '03 September 1973',
  gender: 'Male',
  ssn: '*****7891',

  address1: '2124, 31b, Brooklyn,',
  address2: '2124, 31b, Brooklyn,',
  zip: '123456',
  city: 'New York',
  state: 'New York',
  country: 'US',
  primaryPhone: '1234567890',
  phoneType: 'Mobile',
  secondaryPhone: '(876) 232-3222',
  phoneType2: 'Home',
  email: 'ashlynn97@gmail.com',
  pcm: 'email',

  rphysician: 'Jerry Jack, MD',
  ecn: 'Ashlynn',
  duc: 'passport',
  pcp: 'Saeed Nick, MD',
  ecnumber: '(876) 232-3222',

  mrn: '22 September 1970',
  address: '2124, 31b, Brooklyn, New York',
  race: 'White',
  ethnicity: 'Hispania',
  eMContact: 'Steve Duncun',
  apptPhysician: 'Jerry Jack, MD',
  eMPhone: '(936) 272-8872',
};

const steps = [
  {
    step: '1',
    title: 'Intake Info.',
    status: 'In Progress',
    active: true,
    content: <IntakeInfo />,
  },
  {
    step: '2',
    title: 'Contact Info..',
    status: 'Pending',
    content: <ContactInfo />,
  },
  {
    step: '3',
    title: 'General Info.',
    status: 'Completed',
    content: <GeneralInfo />,
  },
];

function Demographic() {
  const [editForm, setEDitForm] = useState(false);

  const editFormAction = () => {
    setEDitForm(!editForm);
  };

  return (
    <div className="demographic-main-wrap" id="demographic">
      <div className="demographic-wrap-in">
        <div className="demographic-form-wrap">
          <form className={`${editForm ? 'editable' : 'non-editable'}`}>
            <div className="form-container">
              {!editForm
                ? (
                  <div className="view-mode-section">
                    <div className="user-profile-wrap">
                      <UserImage UserImageData={UserImageData} />
                      <h2>Ashlynn Westervelt (F)</h2>
                    </div>
                    <div className="row-wrap mt-28-neg">
                      <div className="first-col">
                        <Input label="MRN" value={DemographicData.mrn} />
                        <Input label="Address" value={DemographicData.address} />
                        <Input label="SSN" value={DemographicData.ssn} />
                        <Input label="DOB" value={DemographicData.dob} />
                      </div>
                      <div className="first-col">
                        <Input label="Primary Phone" value={DemographicData.primaryPhone} />
                        <Input label="Email" value={DemographicData.email} />
                        <Input label="Race" value={DemographicData.race} />
                        <Input label="Ethnicity" value={DemographicData.ethnicity} />
                      </div>
                    </div>
                    <Separator className="dotted-line" />
                    <div className="row-wrap">
                      <div className="first-col">
                        <Input label="EM Contact" value={DemographicData.eMContact} />
                        <Input label="PCP" value={DemographicData.pcp} />
                        <Input label="Appt. Physician" value={DemographicData.apptPhysician} />
                      </div>
                      <div className="first-col">
                        <Input label="EM Phone" value={DemographicData.eMPhone} />
                        <Input label="Ref. Physician" value={DemographicData.rphysician} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="edit-mode-section">
                    <div className="mobileview">
                      <h2>Add Information</h2>
                      <StepAction steps={steps} />
                    </div>
                    <div className="desktop-view">
                      <h2>Intake Information</h2>
                      <IntakeInfo />
                      <Separator className="dotted-line" />
                      <h2>Contact Information</h2>
                      <ContactInfo />
                      <Separator className="dotted-line" />
                      <h2>General Information</h2>
                      <GeneralInfo />
                    </div>
                  </div>
                )}
              <div className="demographic-action-btn">
                <Button className="link edit-icon sprite-img-before" onClick={editFormAction} />
                {!editForm && <Button className="link user-icon sprite-img-before" /> }
                {editForm && <Button className="link save-icon sprite-img-before" /> }
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Demographic;
