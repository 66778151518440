import React from 'react';
import './map.scss';

const Map = () => (
  <div className="map-area">
    <img className="desktop-img" src={`${process.env.PUBLIC_URL}/images/map.png`} width="100%" alt="logo_img" />
    <img className="mobile-img" src={`${process.env.PUBLIC_URL}/images/map-mobile.png`} width="100%" alt="logo_img" />
  </div>
);

export default Map;
