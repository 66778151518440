import React from 'react';
import { Collapse } from 'antd';
import './accordion.scss';

const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}

const Accordion = (props) => {
  const { panelList } = props;

  const getPanel = (item, index) => {
    const Comp = item?.componentName;
    if (item?.text) {
      return (
        <Panel header={item.header} key={index + 1}>
          {item?.componentName ? <Comp /> : <p>{item?.text}</p>}
        </Panel>
      );
    }

    return (
      <Panel header="Please pass your Title" key={index + 1}>
        <Collapse onChange={callback}>
          {getPanel(item?.data, index)}
        </Collapse>
      </Panel>
    );
  };

  return (
    <Collapse defaultActiveKey={['1']} accordion onChange={callback}>
      {panelList.map((item, index) => getPanel(item?.data, index))}
    </Collapse>
  );
};

export default Accordion;
