import React from 'react';
import './pressure.scss';

const Pressure = () => (
  <div className="pressure-section">
    <h2 className="pressure-title">Pressure</h2>
    <div className="pressure-graph">
      <img src={`${process.env.PUBLIC_URL}/images/pressure.png`} width="100%" alt="" />
    </div>
  </div>
);

export default Pressure;
