import React from 'react';
import Input from '../../../../components/Form/Input';

const DemographicData = {
  fname: 'First Name',
  mname: 'Middle Name',
  lname: 'Last Name',
  dob: '03 September 1973',
  gender: 'Gender',
  ssn: 'SSN',
};

const IntakeInfo = () => (
  <div className="row-wrap">
    <div className="first-col">
      <Input value={DemographicData.fname} />
      <Input value={DemographicData.mname} />
      <Input value={DemographicData.lname} />
    </div>
    <div className="first-col">
      <Input value={DemographicData.dob} />
      <Input value={DemographicData.gender} />
      <Input value={DemographicData.ssn} />
    </div>
  </div>
);

export default IntakeInfo;
