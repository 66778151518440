import React from 'react';
import { Input } from 'antd';
import './inputgroup.scss';

const InputGroup = ({
  label, className, defaultVal1, defaultVal2,
}) => (
  <div className={`custom-group-input inline-field ${className}`}>
    {label && (
    <label className="ant-form-item-no-colon">
      {label}
      :
    </label>
    )}
    <Input.Group compact>
      <Input className="extension" style={{ width: '20%' }} defaultValue={defaultVal1} />
      <Input style={{ width: '80%' }} defaultValue={defaultVal2} />
    </Input.Group>
  </div>
);

export default InputGroup;
