import React from 'react';
import Input from '../../../../components/Form/Input';
import SelectBox from '../../../../components/Selectbox';
import InputGroup from '../../../../components/Form/InputGroup';

const DemographicData = {
  address1: 'Address Line 1,',
  address2: 'Address Line 2,',
  zip: 'Zip Code',
  city: 'City',
  state: 'State',
  country: 'Country',
  primaryPhone: 'Primary Phone',
  phoneType: 'Phone Type',
  secondaryPhone: 'Secondary Phone',
  phoneType2: 'phone Type',
  email: 'Email',
  pcm: 'Preferred Contact Method',
};
const selectBoxValue = [
  'Phone Type',
  'Mobile',
  'Landline',
  'Office',
];

const ContactInfo = () => (
  <div className="row-wrap">
    <div className="first-col">
      <Input label="" value={DemographicData.address1} />
      <Input label="" value={DemographicData.address2} />
      <Input label="" value={DemographicData.zip} />
      <Input label="" value={DemographicData.city} />
      <Input label="" value={DemographicData.state} />
      <Input label="" value={DemographicData.country} />
    </div>
    <div className="first-col">
      <Input label="" value={DemographicData.primaryPhone} />
      <InputGroup defaultVal1="123" defaultVal2="1236780" />
      <Input label="" value={DemographicData.phoneType} />
      <SelectBox selectBoxValue={selectBoxValue} />
      <Input label="" value={DemographicData.secondaryPhone} />
      <Input label="" value={DemographicData.phoneType2} />
      <Input label="" value={DemographicData.email} />
      <Input label="" value={DemographicData.pcm} />
    </div>
  </div>
);

export default ContactInfo;
