import React from 'react';
import Button from '../Button';
import './steps.scss';

const StepAction = (props) => {
  const { steps } = props;
  const [current, setCurrent] = React.useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  return (
    <>
      <div className="step-slide">
        {steps.map((item) => (

          <div className={`step-dot ${item.active && 'active'}`} key={item.title}>
            <div><span>{item.step}</span></div>
            <div className="titles">{item.title}</div>
            <div className={`status ${item.status === 'In Progress' && 'progress'} ${item.status === 'Pending' && 'Pending'} ${item.status === 'Completed' && 'Completed'}`}>{item.status}</div>
          </div>
        ))}
      </div>
      <div className="steps-content">{steps[current].content}</div>
      <div className="steps-action">
        {current < steps.length - 1 && (
          <>
            <Button className="outline" onClick={() => prev()}>
              Cancel
            </Button>
            <Button className="primary" onClick={() => next()}>
              Submit
            </Button>
          </>
        )}
        {current === steps.length - 1 && (
          <>
            <Button className="outline" onClick={() => prev()}>
              Cancel
            </Button>
            <Button className="primary" onClick={() => next()}>
              Submit
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default StepAction;
