import React from 'react';
import './appointmentcard.scss';

const AppointmentCard = (props) => {
  const { cardDetail } = props;
  const {
    image, date, time, activeClass,
  } = cardDetail;

  return (
    <div className={`appointment-card ${activeClass === true ? 'active' : ''}`}>
      <div className="user-profile-wrap">
        <div className="img-area">
          <img src={`${process.env.PUBLIC_URL}${image}`} width="60" alt="logo_img" />
        </div>
        <div className="user-details">
          <p className="name">{cardDetail.name}</p>
          <p className="details">{cardDetail.occupasion}</p>
        </div>
      </div>
      <div className="date-time">
        <p className="date sprite-img-before">{date}</p>
        <p className="mid-at">at</p>
        <p className="time sprite-img-before">{time}</p>
      </div>
      <div className="appointment-arrow sprite-img-before" />
    </div>
  );
};

export default AppointmentCard;
