import React from 'react';
import AppointmentCard from '../../../../components/AppointmentCard';
import './appointments.scss';

const cardDetail = {
  name: 'Dr. Gretchen Bergson',
  occupasion: 'Ophthalmologist',
  date: '21 / 12/ 2021',
  time: '18:00 PM',
  image: '/images/user-img-1.png',
  activeClass: true,
  arrow: true,
};
const cardDetail2 = {
  name: 'Dr. Gretchen Bergson',
  occupasion: 'Ophthalmologist',
  date: '21 / 12/ 2021',
  time: '18:00 PM',
  image: '/images/user-img-1.png',
  activeClass: false,
  arrow: true,
};

const Appointments = () => (
  <div className="notification-acc-wrap">
    <AppointmentCard cardDetail={cardDetail} />
    <AppointmentCard cardDetail={cardDetail2} />
  </div>
);

export default Appointments;
