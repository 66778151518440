import React from 'react';
import './separator.scss';

const Separator = (props) => {
  const { className } = props;
  return (
    <div className={`separator ${className || ''}`} />
  );
};

export default Separator;
