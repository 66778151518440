
import React from 'react';
import Accordion from '../../components/Accordion';
import Dashboard from '../Dashboard';
import Demographic from '../Demographic';
import Document from '../Document';
import Financial from '../Financial';
import Insurance from '../Insurance';
import Appointment from '../Appointment';
import Prescription from '../Prescription';
import Concent from '../Concent';

const panelList = [
  {
    data: {
      text: 'text1',
      header: 'Dashboard',
      componentName: Dashboard,
    },
  },
  {
    data: {
      text: 'text2',
      header: 'Demographic',
      componentName: Demographic,
    },
  },
  {
    data: {
      text: 'text3',
      header: 'Insurance',
      componentName: Insurance,
    },
  },
  {
    data: {
      text: 'text3',
      header: 'Payments',
      componentName: Financial,
    },
  },
  {
    data: {
      text: 'text3',
      header: 'Documents',
      componentName: Document,
    },
  },
  {
    data: {
      text: 'text3',
      header: 'Appointment',
      componentName: Appointment,
    },
  },
  {
    data: {
      text: 'text3',
      header: 'Prescription',
      componentName: Prescription,
    },
  },
  {
    data: {
      text: 'text3',
      header: 'Concent Form',
      componentName: Concent,
    },
  },
];

function MainContent() {
  return (
    <div className="main-content">
      <Accordion panelList={panelList} />
    </div>
  );
}

export default MainContent;
