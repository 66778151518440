import React, { useState } from 'react';
import { Modal } from 'antd';
import './modal.scss';

const ModalBox = (props) => {
  const { children, className, width } = props;
  const [modalVisible, setModal1Visible] = useState(true);
  const modalToggle = () => {
    setModal1Visible(!modalVisible);
  };
  return (
    <Modal
      className={className}
      width={width}
      title=""
      centered
      visible={modalVisible}
      onOk={() => modalToggle}
      footer=""
      header=""
    >
      {children}
    </Modal>
  );
};

export default ModalBox;
