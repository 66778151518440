import React from 'react';
import Input from '../../../../components/Form/Input';
import FileUploader from '../../../../components/FileUploader';

const DemographicData = {
  rphysician: 'Referring physician',
  ecn: 'Emergency Contact Name',
  duc: 'Document Upload Category',
  pcp: 'Primary Care Physician',
  ecnumber: 'Emergency Contact No',
};
const defaultFileList = [
  {
    uid: '1',
    name: 'xxx11.png',
    defaultValue: 'No----sen',
    status: 'done',
    response: 'Server Error 500', // custom error message to show
    url: 'http://www.baidu.com/xxx.png',
  },
];

const GeneralInfo = () => (
  <div className="row-wrap">
    <div className="first-col">
      <Input value={DemographicData.rphysician} />
      <Input value={DemographicData.ecn} />
      <Input value={DemographicData.duc} />
    </div>
    <div className="first-col">
      <Input value={DemographicData.pcp} />
      <Input value={DemographicData.ecnumber} />
      <FileUploader defaultFileList={defaultFileList} />
    </div>
  </div>
);

export default GeneralInfo;
