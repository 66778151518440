import { Map } from 'immutable';
import * as enumAction from '../actions/enum';

const initialState = Map({
  enums: {},
  field: {},
});

const actionsMap = {
  [enumAction.GET_ENUM_FIELD_DATA]: (state, { enumId, parentId }) => {
    const field = state.get('field');
    let enumById = field[enumId];
    if (parentId && enumById) {
      if (enumById[parentId]) {
        enumById[parentId].loading = false;
      } else {
        enumById[parentId] = {};
        enumById[parentId].loading = true;
      }
    } else if (enumById) {
      enumById.loading = false;
    } else {
      enumById = {};
      enumById.loading = true;
    }
    return state.set('field', { ...field, [enumId]: enumById });
  },
  [enumAction.SET_ENUM_FIELD_DATA]: (state, { enumId, parentId, data }) => {
    const field = state.get('field');
    const enumById = field[enumId];
    if (parentId && enumById) {
      enumById[parentId] = {};
      enumById[parentId].loading = false;
      enumById[parentId].data = data;
    } else {
      enumById.loading = false;
      enumById.data = data;
    }
    return state.set('field', { ...field, [enumId]: enumById });
  },
  [enumAction.SET_ENUM_MASTER_DATA]: (
    state, { enums },
  ) => Map(state.withMutations((mutatedState) => mutatedState.set(
    'enums', enums,
  ))),
  [enumAction.GET_ENUM_MASTER_DATA]: (state) => Map(
    state.withMutations(
      (mutatedState) => mutatedState.set('loading', true).set('error', null),
    ),
  ),
  [enumAction.SET_LOADING]: (
    state, { flag = !state.get('loading') },
  ) => state.set('loading', flag),
  [enumAction.SET_ERROR]: (state, { error }) => state.set('error', error).set('loading', false),
};

export default function enumReducers(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
