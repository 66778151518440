
import React, { useState } from 'react';
import './financial.scss';
import CurrentBalance from '../../components/CurrentBalanceCard';
import PlanCard from '../../components/PlanCard';
import FilterDropdown from '../../components/FilterDropdown';
import Table from '../../components/Table';
import Button from '../../components/Button';
import Carousel from '../../components/Carousel';
import Modal from '../../components/Modals';

const CurrentBalanceDetail = {
  amount: '50',
  currentBalance: 'Current Balance',
  valid: 'as of Sep. 15, 2021',
};
const CardValue1 = {
  amount: '100',
  activation: 'Active Plan',
  valid: 'as of Sept. 25, 2021',
  activeClass: false,
  indication: '2',
};
const CardValue2 = {
  amount: '250',
  activation: 'Active Plan',
  valid: 'as of Sept. 25, 2021',
  activeClass: false,
  indication: '1',
};
const CardValue3 = {
  amount: '250',
  completePlan: true,
  activeClass: true,
};

const filterValue = [
  'Select Month',
  'January',
  'February',
  'March',
];

const getDownloadBtn = (title) => (
  <Button className="download-btn sprite-img-before">{title}</Button>
);

const tableContent = {
  head: [{
    date: 'Date',
    amount: 'Amount',
    print: 'Print',
  }],
  body: [
    {
      date: 'September 30, 2021',
      Amount: '$50',
      Print: getDownloadBtn('Download Receipt'),
    },
    {
      date: 'September 30, 2021',
      Amount: '$50',
      Print: getDownloadBtn('Download Receipt'),
    },
    {
      date: 'September 30, 2021',
      Amount: '$50',
      Print: getDownloadBtn('Download Receipt'),
    },
  ],

};

const modalTableContent = {
  head: [{
    date: 'Date',
    amount: 'Amount Paid',
    amountLeft: 'Amount Left',
  }],
  body: [
    {
      date: 'September 30, 2021',
      Amount: '$50',
      amountLeft: '$250',
    },
    {
      date: 'September 30, 2021',
      Amount: '$50',
      amountLeft: '$250',
    },
    {
      date: 'September 30, 2021',
      Amount: '$50',
      amountLeft: '$250',
    },
  ],

};
const carouselList = [
  {
    comp: <PlanCard CardValue={CardValue1} />,
  },
  {
    comp: <PlanCard CardValue={CardValue2} />,
  },
  {
    comp: <PlanCard CardValue={CardValue3} />,
  },
];

function Financial() {
  const [openPlanModal, setOpenPlanModal] = useState(false);
  const modalAction = () => {
    setOpenPlanModal(!openPlanModal);
  };
  return (
    <div className="payment-wrap" id="financial">
      <div className="payment-main-wrap">
        <div className="current-balance-section">
          <h2>Current Balance</h2>
          <div className="current-balance-wrap">
            <CurrentBalance CurrentBalanceDetail={CurrentBalanceDetail} />
          </div>
        </div>
        <div className="payment-plan-wrap">
          <h2>Payment Plans</h2>
          <div className="payment-plan-wrap-in">
            <div className="desktop-only">
              <div
                className="payment-plan-wrap-in-desktop"
                onClick={modalAction}
                onKeyDown={modalAction}
                role="button"
                tabIndex={0}
              >
                <PlanCard CardValue={CardValue1} />
                <PlanCard CardValue={CardValue2} onClick={modalAction} />
                <PlanCard CardValue={CardValue3} onClick={modalAction} />
              </div>
            </div>
          </div>
          <div className="mobile-only payment-plan-bg">
            <Carousel carouselList={carouselList} />
          </div>
        </div>
      </div>
      <div className="separator" />
      <div className="receipt-table-wrap">
        <div className="receipt-table-heading">
          <h2>Receipt Table</h2>
          <FilterDropdown filterValue={filterValue} />
        </div>
        <Table tableContent={tableContent} />
      </div>
      {openPlanModal && (
      <Modal className="view-payment-plan" width="80rem">
        <h1>View Payment Plans</h1>
        <div className="plan-details">
          <p>
            <span className="key">Payment Plan Amount:</span>
            <span className="value">$50</span>
          </p>
          <p>
            <span className="key">Plan Start Date:</span>
            <span className="value">September 15, 2021</span>
          </p>
          <p>
            <span className="key">Frequency:</span>
            <span className="value">Every Month</span>
          </p>
        </div>
        <Table tableContent={modalTableContent} />
      </Modal>
      )}
    </div>
  );
}

export default Financial;
