import React from 'react';
import './button.scss';

const Button = (props) => {
  const { className, children, onClick } = props;
  return (
    <button type="button" onClick={onClick} className={`btn ${className}`}>
      {children}
    </button>
  );
};

export default Button;
