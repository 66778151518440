import React from 'react';
import { Input } from 'antd';

import './input.scss';

const TextInput = ({
  children, label, Component, className, ...props
}) => (
  <div className={`custom-text-input inline-field ${className}`}>
    {label && (
    <label className="ant-form-item-no-colon">
      {label}
      :
    </label>
    )}
    <Component {...props} />
  </div>
);

TextInput.defaultProps = {
  Component: Input,
};

export default TextInput;
