import moment from 'moment';
import toLower from 'lodash/toLower';
import lowerCase from 'lodash/lowerCase';
import { apiUrls } from '../api/constants';
import api from '../api';

const typeExtractor = (fileTypeCheck) => {
  let fileType = null;
  if (fileTypeCheck) {
    const type = toLower(fileTypeCheck);
    if (type.indexOf('pdf') > -1) {
      fileType = 'PDF';
    } else if (type.indexOf('jpeg') > -1) {
      fileType = 'JPEG';
    } else if (type.indexOf('jpg') > -1) {
      fileType = 'JPG';
    } else if (type.indexOf('png') > -1) {
      fileType = 'PNG';
    }
  }
  return fileType;
};

function retry(
  fn,
) {
  return new Promise((resolve) => {
    fn()
      .then(resolve)
      .catch(() => {
        window.location.reload();
      });
  });
}

function getTimeString(yearsInt, monthsInt, weeksInt, daysInt) {
  const years = parseInt(yearsInt || 0, 10);
  const months = parseInt(monthsInt || 0, 10);
  const weeks = parseInt(weeksInt || 0, 10);
  const days = parseInt(daysInt || 0, 10);
  let timeString = '';
  if (years > 1) {
    timeString = `${years} years`;
  } else if (years > 0) {
    timeString = `${years} year`;
  }

  if (months > 1) {
    timeString += ` ${months} months`;
  } else if (months > 0) {
    timeString += ` ${months} month`;
  }

  if (weeks > 1) {
    timeString += ` ${weeks} weeks`;
  } else if (weeks > 0) {
    timeString += ` ${weeks} week`;
  }

  if (days > 1) {
    timeString += ` ${days} days`;
  } else if (days > 0) {
    timeString += ` ${days} day`;
  }
  return timeString.trim();
}

const isInputExistInMap = (input, itemsKeys, item = {}) => {
  const mapKeys = itemsKeys || Object.keys(item || {});
  // eslint-disable-next-line no-unused-expressions
  return mapKeys?.some((ele, index) => {
    if (lowerCase(item[mapKeys[index]]).indexOf(lowerCase(input)) > -1) {
      return true;
    }
    return false;
  });
};

const getName = (fName, mName, lName) => {
  const name = [];
  if (fName) {
    name.push(fName);
  }
  if (mName) {
    name.push(mName);
  }
  if (lName) {
    name.push(lName);
  }
  return name.join(' ');
};

const getString = (data) => {
  const name = [];
  // eslint-disable-next-line no-unused-expressions
  data?.forEach((item) => {
    if (item) {
      name.push(item);
    }
  });
  return name.join(' ');
};

const typeObj = {
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  png: 'image/png',
};

const contentTypeCalculator = (imageType) => {
  if (!imageType) return false;
  const type = imageType.toLowerCase();
  return typeObj[type] || type;
};

const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumberString;
};

const getInitials = (name) => {
  if (Array.isArray(name)) {
    // eslint-disable-next-line no-param-reassign
    name = getString(name);
  }

  const initials = name.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);

  if (name) {
    return initials.join('').toUpperCase();
  }

  return initials;
};

const encodeUserSettings = (filters = {}) => {
  let parsedFilter = filters;
  if (typeof filters === 'string') {
    try {
      parsedFilter = JSON.parse(filters);
    } catch (settingsParseError) {
      console.error('encodeUserSettings | settingsParseError', settingsParseError);
      return filters;
    }
  }
  parsedFilter.lastModified = moment().unix();
  return JSON.stringify(parsedFilter);
};

const decodeUserSettings = (settings, encode = false) => {
  if (!settings) return encode ? '' : {};
  let parsedFilter = {};
  if (typeof settings === 'string') {
    try {
      parsedFilter = JSON.parse(settings);
    } catch (settingsParseError) {
      console.error('decodeUserSettings | settingsParseError', settingsParseError);
      return encode ? '' : {};
    }
  } else parsedFilter = { ...settings };
  if (encode) {
    delete parsedFilter.lastModified;
    return Object.entries(parsedFilter).filter(([, val]) => val && val !== 'undefined').map(([key, val]) => {
      if (Array.isArray(val)) {
        return [key, window.escape(val.join(','))].join('=');
      }
      return [key, val].map(window.escape).join('=');
    }).join('&');
  }
  return parsedFilter;
};

const extractNumber = (number) => {
  const numberPattern = /\d+/g;
  return number?.toString().trim().match(numberPattern).join('');
};

const isAuthorized = (roles) => {
  let loggedInUserRole = null;
  try {
    const { roleName } = JSON.parse(localStorage.getItem('user') || '{}');
    loggedInUserRole = (roleName || '').toLowerCase();
  } catch {
    return false;
  }
  if (loggedInUserRole) {
    const rolesArray = Array.isArray(roles) ? roles : [roles];
    return rolesArray.some((role) => role === loggedInUserRole);
  }
  return false;
};

const updateUserRole = async () => {
  try {
    const token = localStorage.getItem('token');
    const userData = (await api.get({ url: apiUrls.GET_USER_PROFILE, token })) || {};
    let user = localStorage.getItem('user');
    user = JSON.parse(user);
    user.roleName = userData?.roleName;
    user.roleId = userData?.roleId;
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('user-profile', JSON.stringify((userData || {})));
    return { roleName: userData?.userName, roleId: userData?.roleId };
  } catch (e) {
    console.warn(e);
  }
  return {};
};

const getLoggedInUserId = () => {
  let user = localStorage.getItem('user');
  user = JSON.parse(user);
  return user?.userId;
};

const urltoFile = (url, filename, mimeType) => {
  // eslint-disable-next-line no-param-reassign
  mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
  return (fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }))
  );
};
const getRandomNumber = () => {
  const crypto = window.crypto || window.msCrypto;
  const array = new Uint32Array(1);
  crypto.getRandomValues(array); // Compliant for security-sensitive use cases
  return array[0];
};

const JArray = JSON.parse('[{"Key": "Prompt1", "Type": "DropDown", "Lable": "J-code", "Order": "1", "Default": "", "Options": [{"Units": "1", "Dosage": "1.25 mg", "J-code": "J9035", "Abbreviation": "IVA", "Medication name": "Avastin"}, {"Units": "3", "Dosage": "0.3 mg", "J-code": "J2778", "Abbreviation": "IVL", "Medication name": "Lucentis"}, {"Units": "4", "Dosage": "4 mg", "J-code": "J3301", "Abbreviation": "IVK", "Medication name": "Kenalog"}, {"Units": "2", "Dosage": "2 mg", "J-code": "J0178", "Abbreviation": "?", "Medication name": "Eylea"}, {"Units": "19", "Dosage": "0.19 mg", "J-code": "J7313", "Abbreviation": "?", "Medication name": "Illuvien"}, {"Units": "1", "Dosage": "0.125 mg", "J-code": "J7316", "Abbreviation": "?", "Medication name": "Jetrea"}, {"Units": "7", "Dosage": "0.7 mg", "J-code": "J7312", "Abbreviation": "?", "Medication name": "Ozurdex"}, {"Units": "40", "Dosage": "4 mg", "J-code": "J3300", "Abbreviation": "?", "Medication name": "Triesence"}], "IsNested": "0"}, {"Key": "Prompt2", "Type": "TextBox", "Lable": "Lot #", "Order": "2", "Default": "", "Options": [], "IsNested": "0"}, {"Key": "Prompt3", "Type": "TextBox", "Lable": "Expiration", "Order": "3", "Default": "", "Options": [], "IsNested": "0"}, {"Key": "Prompt4", "Type": "TextBox", "Lable": "Dosage", "Order": "4", "Default": "", "Options": [], "IsNested": "0"}, {"Key": "Prompt5", "Type": "TextBox", "Lable": "Units", "Order": "5", "Default": "", "Options": [], "IsNested": "0"}]');

const crudSelector = (state, id, type) => {
  if (!id) {
    return null;
  }
  if (state.crud) {
    if (state.crud.get('currentTab')) {
      return state.crud.get(state.crud.get('currentTab')) && state.crud.get(state.crud.get('currentTab'))
        .get(id) && state.crud.get(state.crud.get('currentTab'))
        .get(id)
        .get(type);
    }
    return state.crud.get(id) && state.crud.get(id).get(type);
  }
  return true;
};

export {
  typeExtractor,
  retry,
  getTimeString,
  isInputExistInMap,
  contentTypeCalculator,
  getName,
  formatPhoneNumber,
  getString,
  getInitials,
  encodeUserSettings,
  decodeUserSettings,
  extractNumber,
  isAuthorized,
  updateUserRole,
  getLoggedInUserId,
  JArray,
  urltoFile,
  getRandomNumber,
  crudSelector,
};
