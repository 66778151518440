import React from 'react';
import MedicationCard from '../../../../components/MedicineCard';
import './medicine.scss';

const medicationCardValue1 = {
  name: 'Metformin',
  dose: '15mg',
  WhenToTake: 'pills X 4 days',
  image: '/images/user-img-1.png',
  activeClass: true,
  instruction: '1 pill consumed for today.',
};
const medicationCardValue2 = {
  name: 'Metformin',
  dose: '15mg',
  WhenToTake: 'pills X 4 days',
  image: '/images/user-img-1.png',
  activeClass: true,
};

const Medicine = () => (
  <div className="notification-acc-wrap">
    <MedicationCard medicationCardValue={medicationCardValue1} />
    <MedicationCard medicationCardValue={medicationCardValue2} />
  </div>
);

export default Medicine;
