import React, { useCallback, useState } from 'react';
import './insuranceCard.scss';

const InsuranceCard2 = () => {
  const [showViewBack, setShowViewBack] = useState(false);
  const viewBack = useCallback(() => {
    setShowViewBack(!showViewBack);
  }, [showViewBack]);
  return (
    <div className={`slide-cards-wrap ${showViewBack && 'show'}`}>
      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={`${process.env.PUBLIC_URL}/images/insurance-card.png`} alt="logo_img" />
          </div>
          <div className="flip-card-back">
            <h1>View Back Details</h1>
          </div>
        </div>
        <div
          className="view-back"
          onClick={viewBack}
          onKeyDown={viewBack}
          role="button"
          tabIndex={0}
        >
          <span className="sprite-img-before">{showViewBack ? 'View Back' : 'View Front' }</span>
        </div>
      </div>
    </div>
  );
};

export default InsuranceCard2;
