import React from 'react';
import { Carousel } from 'antd';
import './carousel.scss';

const Carousels = (props) => {
  const { carouselList } = props;
  const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };
  return (
    <div className="carousel-img">
      <Carousel arrows>
        {carouselList.map((item, i) => (
          <div key={i} style={contentStyle}>
            <div key={i}>{item.comp}</div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Carousels;
