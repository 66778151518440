import React from 'react';
import './vision.scss';

const Vision = () => (
  <div className="vision-section">
    <h2 className="vision-title">Vision</h2>
    <div className="vision-graph">
      <img src={`${process.env.PUBLIC_URL}/images/vision.png`} width="100%" alt="" />
    </div>
  </div>
);

export default Vision;
