import React, { useState, useCallback } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Calendar from 'react-calendar';
import Button from '../Button';
import Table from '../Table';
import Separator from '../Separator';
import './bookappointmentlocation.scss';
import 'react-calendar/dist/Calendar.css';

const tableContent = {
  head: [{
    date: 'Morning',
    amount: 'Evening',
  }],
  body: [
    {
      date: '07:30',
      Amount: '13:00',
    },
    {
      date: '08:00',
      Amount: '13:00',
    },
    {
      date: '08:30',
      Amount: '14:00',
    },
    {
      date: '09:00',
      Amount: '13:00',
    },
    {
      date: '07:30',
      Amount: '13:00',
    },
  ],

};

const BookAppointmentLocation = (props) => {
  const { AppointmentBookLocation, submitToastData, showDoctorAction } = props;
  const {
    image, name, occupation, location1, location2, location3, location4,
  } = AppointmentBookLocation;
  const [tagActive, setTagActive] = useState(false);
  const [showViewSlot, setShowViewSlot] = useState(true);
  const [confirmBook, setConfirmBook] = useState(false);
  const [viewOnMobile, setViewOnMobile] = useState(false);
  const tagAction = useCallback(() => {
    setTagActive(!tagActive);
  }, [tagActive]);
  const conformBooking = useCallback(() => {
    setConfirmBook(!confirmBook);
    setShowViewSlot(!showViewSlot);
    setTagActive(!tagActive);
  }, [confirmBook, showViewSlot, tagActive]);

  const viewSlot = useCallback(() => {
    setShowViewSlot(!showViewSlot);
  }, [showViewSlot]);
  const viewMobileAction = useCallback(() => {
    setViewOnMobile(!viewOnMobile);
  }, [viewOnMobile]);
  const [value, onChange] = useState(new Date());
  const toastMsg = () => {
    submitToastData();
  };
  return (
    <div className="book-appointment-location-wrap active">
      <div className="user-profile-wrap">
        <div className="img-area">
          <img src={`${process.env.PUBLIC_URL}${image}`} width="70" alt="logo_img" />
        </div>
        <div className="user-details">
          <p className="name">{name}</p>
          <p className="details">{occupation}</p>
          {confirmBook ? (
            <div className="confirm-booking-tags">
              <div className="tags active">
                {location1}
              </div>
              <div className="tags green-active">
                December 30, 2021
              </div>
              <div className="tags active">
                14:00 PM
              </div>
            </div>
          ) : ''}
          {!showViewSlot ? (
            <>
              <div className="tags active">
                {location1}
              </div>
              <Separator className="doted" />
              <div className="availability-calendar">
                <div className={`initial-hide-on-mob ${viewOnMobile && 'view-on-mobile'}`}>
                  <div className="calendar-area">
                    <Calendar
                      onChange={onChange}
                      value={value}
                    />
                  </div>
                  <div className="indications">
                    <div>
                      <span className="red" />
                      Unavailable Slots
                    </div>
                    <div>
                      <span className="green" />
                      Available Slots
                    </div>
                    <div>
                      <span className="gray" />
                      Active Slots
                    </div>
                  </div>
                </div>
                { !viewOnMobile
                && (
                <div className="time-slot-area">
                  <h2>Available Slots for Dec 30, 2021</h2>
                  <Table tableContent={tableContent} />
                </div>
                )}
                { viewOnMobile
                && (
                <div
                  className="mobile-only view-slot-calendar"
                  onClick={viewMobileAction}
                  onKeyDown={tagAction}
                  role="button"
                  tabIndex={0}
                >
                  View Slots
                </div>
                )}
                { !viewOnMobile
                && (
                <div
                  className="mobile-only view-slot-calendar"
                  onClick={viewMobileAction}
                  onKeyDown={tagAction}
                  role="button"
                  tabIndex={0}

                >
                  View Calendar
                </div>
                )}
              </div>
            </>
          ) : ''}
        </div>
      </div>
      {showViewSlot && !confirmBook ? (
        <div className="card-footer">
          <div className="location-tags">
            <div
              onClick={tagAction}
              onKeyDown={tagAction}
              role="button"
              tabIndex={0}
              className={`tags ${tagActive ? 'active' : ''}`}
            >
              {location1}
            </div>
            <div className="tags">
              {location2}
            </div>
            <div className="tags">
              {location3}
            </div>
            <div className="tags">
              {location4}
            </div>
          </div>
          <Button onClick={viewSlot} className={`outline arrow ${tagActive ? 'active' : ''}`}><span className="sprite-img-before">Check Availability</span></Button>
        </div>
      ) : ''}
      {!showViewSlot ? (
        <div className="card-footer btn-wrap">
          <Button className="outline">Cancel</Button>
          <Button className="primary" onClick={conformBooking}>Confirm Slots</Button>
        </div>
      ) : ''}
      {confirmBook
      && (
      <div className="card-footer btn-wrap mt-20">
        <Button className="outline">Cancel</Button>
        <Button className="primary" onClick={() => { toastMsg(); showDoctorAction(); }}>Confirm Booking</Button>
      </div>
      )}
      <div className="checkbox-wrap">
        <div className="active-check" />
      </div>
    </div>
  );
};

export default BookAppointmentLocation;
