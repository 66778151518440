
import React from 'react';
import './currentbalance.scss';
import Button from '../Button';

function CurrentBalance(props) {
  const { CurrentBalanceDetail } = props;
  const { amount, currentBalance, valid } = CurrentBalanceDetail;
  return (
    <div className="current-balance">
      <h1>
        $
        {amount}
      </h1>
      <h2>{currentBalance}</h2>
      <p>{valid}</p>
      <div className="balance-btn">
        <Button className="outline">Make Payment</Button>
        <Button className="primary">Payment Plan</Button>
      </div>
    </div>
  );
}

export default CurrentBalance;
